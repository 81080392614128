import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/autoplay'
import 'swiper/css'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
// Import Swiper styles
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function SwiperContainer({photos}:{photos:any}){
  return (
    <Swiper
      modules={[Autoplay, Navigation, Pagination]}
      slidesPerView={1}
      autoplay={{delay: 3000}}
      className='z-10 w-[300px] md:w-full lg:w-[812px] h-[228px] md:h-[525px] flex justify-center items-center'
      navigation
    >
      {
        photos.map((photo:any, index:number)=>(
          <SwiperSlide key={index} className='text-white text-center'><img loading='lazy' alt='Carousel' className='w-full h-[228px] md:h-full' src={photo}/></SwiperSlide>
        ))
      }
    </Swiper>
  );
};