import React, { useEffect } from 'react'
import SectorTemplate, { SectorData } from './SectorTemplate'
// import { engineeringSector, oilSector, environmentalSector, manufacturingSector, generalTradeSector, agenciesSector, } from './SectorsObjects';
import { editStyles, observeElements } from '../../script';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/config';

export default function Services() {
  const {t} = useTranslation();
  useEffect(()=>{
    window.scrollTo(0, 0);
    observeElements();
    editStyles();
  },[])


  function returnImages(number: number, path: string){

    const images = [];
    
    for (let i = 1; i <= number; i++) {
      images.push(require(`../../Images/${path + i}.jpg`));
    }
    return images
  }

  const engineeringSector: SectorData = {
    head: t("services.engineeringSector.head"),
    paragraphs: [t("services.engineeringSector.paragraphs.p0"),t("services.engineeringSector.paragraphs.p1")],
    serviceDetails: [
        { head: t("services.engineeringSector.serviceDetails.service0.head"),paragraph: t("services.engineeringSector.serviceDetails.service0.paragraph")},
        { head: t("services.engineeringSector.serviceDetails.service1.head"),paragraph: t("services.engineeringSector.serviceDetails.service1.paragraph")},
        { head: t("services.engineeringSector.serviceDetails.service2.head"),paragraph: t("services.engineeringSector.serviceDetails.service2.paragraph")},
        { head: t("services.engineeringSector.serviceDetails.service3.head"),paragraph: t("services.engineeringSector.serviceDetails.service3.paragraph")},
        { head: t("services.engineeringSector.serviceDetails.service4.head"),paragraph: t("services.engineeringSector.serviceDetails.service4.paragraph")},
        { head: t("services.engineeringSector.serviceDetails.service5.head"),paragraph: t("services.engineeringSector.serviceDetails.service5.paragraph")},
        { head: t("services.engineeringSector.serviceDetails.service6.head"),paragraph: t("services.engineeringSector.serviceDetails.service6.paragraph")},
        { head: t("services.engineeringSector.serviceDetails.service7.head"),paragraph: t("services.engineeringSector.serviceDetails.service7.paragraph")},
        { head: t("services.engineeringSector.serviceDetails.service8.head"),paragraph: t("services.engineeringSector.serviceDetails.service8.paragraph") }
    ],
    photos: returnImages(9, "EngServices/eng")
}


  const oilSector: SectorData = {
    head: t("services.oilSector.head"),
    paragraphs: [t("services.oilSector.paragraphs.p0"),t("services.oilSector.paragraphs.p1")],
    serviceDetails: [
        {
            head: t("services.oilSector.serviceDetails.service0.head"),
            paragraph: [
              { title: t("services.oilSector.serviceDetails.service0.paragraph.p0.title"), description:t("services.oilSector.serviceDetails.service0.paragraph.p0.description")},
              { title: t("services.oilSector.serviceDetails.service0.paragraph.p1.title"), description:t("services.oilSector.serviceDetails.service0.paragraph.p1.description")},
              { title: t("services.oilSector.serviceDetails.service0.paragraph.p2.title"), description:t("services.oilSector.serviceDetails.service0.paragraph.p2.description")},
              { title: t("services.oilSector.serviceDetails.service0.paragraph.p3.title"), description:t("services.oilSector.serviceDetails.service0.paragraph.p3.description") }
            ]
        },
        {
            head: t("services.oilSector.serviceDetails.service1.head"),
            paragraph: [
              { title: t("services.oilSector.serviceDetails.service1.paragraph.p0.title"), description:t("services.oilSector.serviceDetails.service1.paragraph.p0.description")},
              { title: t("services.oilSector.serviceDetails.service1.paragraph.p1.title"), description:t("services.oilSector.serviceDetails.service1.paragraph.p1.description")},
            ]
        }
    ],
    photos: returnImages(7, "OilServices/oil")
}

  const environmentalSector: SectorData = {
    head: t("services.environmentalSector.head"),
    paragraphs: [t("services.environmentalSector.paragraphs.p0"),t("services.environmentalSector.paragraphs.p1")],
    serviceDetails: [
      {
          head: t("services.environmentalSector.serviceDetails.service0.head"),
          paragraph: [
            { title: t("services.environmentalSector.serviceDetails.service0.paragraph.p0.title"), description:t("services.environmentalSector.serviceDetails.service0.paragraph.p0.description")},
            { title: t("services.environmentalSector.serviceDetails.service0.paragraph.p1.title"), description:t("services.environmentalSector.serviceDetails.service0.paragraph.p1.description")},
            { title: t("services.environmentalSector.serviceDetails.service0.paragraph.p2.title"), description:t("services.environmentalSector.serviceDetails.service0.paragraph.p2.description")},
            { title: t("services.environmentalSector.serviceDetails.service0.paragraph.p3.title"), description:t("services.environmentalSector.serviceDetails.service0.paragraph.p3.description") },
            { title: t("services.environmentalSector.serviceDetails.service0.paragraph.p4.title"), description:t("services.environmentalSector.serviceDetails.service0.paragraph.p4.description") }
          ]
      },
      {
          head: t("services.engineeringSector.serviceDetails.service1.head"),
          paragraph: [
            { title: t("services.environmentalSector.serviceDetails.service1.paragraph.p0.title"), description:t("services.environmentalSector.serviceDetails.service1.paragraph.p0.description")},
            { title: t("services.environmentalSector.serviceDetails.service1.paragraph.p1.title"), description:t("services.environmentalSector.serviceDetails.service1.paragraph.p1.description")},
          ]
      }
  ],
    photos: returnImages(7, "EnvServices/env")
}

  const manufacturingSector : SectorData = {
    head: t("services.manufacturingSector.head"),
    paragraphs: [t("services.manufacturingSector.paragraphs.p0"), t("services.manufacturingSector.paragraphs.p1"), t("services.manufacturingSector.paragraphs.p2")],
    serviceDetails: [
      { head: t("services.manufacturingSector.serviceDetails.service0.head"),paragraph: t("services.manufacturingSector.serviceDetails.service0.paragraph")},
      { head: t("services.manufacturingSector.serviceDetails.service1.head"),paragraph: t("services.manufacturingSector.serviceDetails.service1.paragraph")},
      { head: t("services.manufacturingSector.serviceDetails.service2.head"),paragraph: t("services.manufacturingSector.serviceDetails.service2.paragraph")},
    ],
    photos: returnImages(5, "ManServices/man")
}


  const generalTradeSector : SectorData = {
    head: t("services.generalTradeSector.head"),
    paragraphs: [t("services.generalTradeSector.paragraphs.p0"),t("services.generalTradeSector.paragraphs.p1")],
    serviceDetails: [
      { head: t("services.generalTradeSector.serviceDetails.service0.head"),paragraph: t("services.generalTradeSector.serviceDetails.service0.paragraph")},
      { head: t("services.generalTradeSector.serviceDetails.service1.head"),paragraph: t("services.generalTradeSector.serviceDetails.service1.paragraph")},
      { head: t("services.generalTradeSector.serviceDetails.service2.head"),paragraph: t("services.generalTradeSector.serviceDetails.service2.paragraph")},
      { head: t("services.generalTradeSector.serviceDetails.service3.head"),paragraph: t("services.generalTradeSector.serviceDetails.service3.paragraph")},
      { head: t("services.generalTradeSector.serviceDetails.service4.head"),paragraph: t("services.generalTradeSector.serviceDetails.service4.paragraph")},
    ],
    photos: returnImages(10, "GenServices/gen")
    
}


  const agenciesSector : SectorData = {
    head: t("services.agenciesSector.head"),
    paragraphs: [t("services.agenciesSector.paragraphs.p0"),t("services.agenciesSector.paragraphs.p1")],
    serviceDetails: [
      { head: t("services.agenciesSector.serviceDetails.service0.head"),paragraph: t("services.agenciesSector.serviceDetails.service0.paragraph")},
      { head: t("services.agenciesSector.serviceDetails.service1.head"),paragraph: t("services.agenciesSector.serviceDetails.service1.paragraph")},
      { head: t("services.agenciesSector.serviceDetails.service2.head"),paragraph: t("services.agenciesSector.serviceDetails.service2.paragraph")},
    ],
    photos: returnImages(7, "AgcServices/agc")
}
  return (
    <>
      <SectorTemplate data={engineeringSector}/>
      <SectorTemplate data={oilSector}/>
      <SectorTemplate data={environmentalSector}/>
      <SectorTemplate data={manufacturingSector}/>
      <SectorTemplate data={generalTradeSector}/>
      <SectorTemplate data={agenciesSector}/>
    </>
  )
}
