import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { NavigationRoutes } from './Routes'
import i18n from '../../i18n/config'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from '../LanguageSwitcher'

type Props = {
    open: boolean;
    isMenuOpen: boolean
    setIsMenuOpen(value: boolean):any;
}
export default function HamburgerMenu(props: Props) {

  const {t} = useTranslation();

  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function closeNotifBar(event: MouseEvent) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        props.setIsMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", closeNotifBar);

    return () => {
      document.removeEventListener("mousedown", closeNotifBar);
    };
}, [menuRef]);
  return (
    // <div id='hammenu' className={`md:hidden fixed top-16 right-0 py-5 px-4 shadow-xl bg-white transition duration-75 ease-linear opacity-0 -translate-y-96  ${props.open ? "block opacity-100 -translate-y-0" : ""}`}>
    <div ref={menuRef} id='hammenu' className={`md:hidden fixed top-16 right-0 py-5 px-4 shadow-xl bg-white transition duration-75 ease-linear opacity-0 z-50 ${props.open ? "block opacity-100 -translate-y-0" : "-translate-y-96"}`}>
        <ul className='flex flex-col text-right gap-10'>
            <li className='hover:underline underline-offset-8'><Link to={NavigationRoutes.Home}>{t("shared.navbar.home")}</Link></li>
            <li className='hover:underline underline-offset-8'><Link to={NavigationRoutes.AboutUs}>{t("shared.navbar.about")}</Link></li>
            <li className='hover:underline underline-offset-8'><Link to={NavigationRoutes.Services}>{t("shared.navbar.services")}</Link></li>
            <li className='hover:underline underline-offset-8'><Link to={NavigationRoutes.Projects}>{t("shared.navbar.projects")}</Link></li>
            <li className='hover:underline underline-offset-8 self-end'><LanguageSwitcher/></li>
        </ul>
    </div>
  )
}
