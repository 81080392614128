export const projectsArabic = {
    title: "مشاريعنا",
    section0: {
        title: "تنفيذ مباشر",
        project0:{head:"مستشفى تكريت المدمج STP", paragraph:""},
        project1:{head:"مركز تأهيل ذو الاحتياجات الخاصة", paragraph:" 4 مراكز - بغداد."},
        project2:{head:"مجمع الشموخ السكني", paragraph:"بغداد."},
        project3:{head:"مجمع سكني لصالح وزارة الصناعة", paragraph:"النباعي - العراق."},
    },
    section1:{
        title: "مكتب الحاني",
        project0:{head:"مجمع مستشفى STP", paragraph:"محافظة نينوى - العراق"},
        project1:{head:"فلاتر زنكورة", paragraph:"محافظة الأنبار."},
        project2:{head:"إعادة تأهيل محطة مياه كركوك الموحدة", paragraph:""},
        project3:{head:"إعادة تأهيل محطة ضخ مياه الأمطار", paragraph:"محافظة الديوانية."},
    },
    section2:{
        title: "مشاريع معالجة المياه",
        project0:{head:"محطة معالجة مياه الكاظمية", paragraph:"25 مليون جالون يومياً - مقوال ثانوي - تسليم مفتاح - بغداد."},
        project1:{head:"محطة ضخ البلديات", paragraph:" 2×25 مليون جالون يومياً - الإدارة والإشراف - بغداد."},
        project2:{head:"محطة ضخ القادسية", paragraph:"20 مليون جالون يومياً - الإدارة والإشراف - بغداد."},
    },
    section3:{
        title: "مشاريع التوريد والتجهيز",
        project0:{head:"معدات كهربائية", paragraph:"توريد كافة معدات الطاقة لمحطات معالجة مياه الصرف الصحي في الجسر لصالح محافظة بغداد بالتعاون مع شركة H&F Pro Aqua GmbH وشركة رؤية الرافدين المحدودة."},
        project1:{head:"أعمدة الإنارة", paragraph:"وزارة الصناعة والمعادن."},
        project2:{head:"ماكينات التغليف", paragraph:"وزارة الصناعة والمعادن."},
        project3:{head:"أنظمة سكادا", paragraph:"تصميم وتوريد جميع معدات وبرمجيات نظام سكادا لمحطة الهندية لمعالجة المياه لصالح وزارة البلديات والأشغال العامة، بالتعاون مع شركة H&F Pro Aqua GmbH وشركة رؤية الرافدين المحدودة."},
    },
    section4:{
        title: "مشاريع اخرى",
        project0:{head:"أعمال المسح", paragraph:"لخط الجهد العالي جهد 400 ك.ف – 132 ك.ف لصالح (المديرية العامة لمشاريع النقل الكهربائي ETP)."},
        project1:{head:"شركة الدايري", paragraph:"معمل السلفنة – سوريا."},
        project2:{head:"مصنع العائلة للمنظفات الصناعية", paragraph:"العراق."},
        project3:{head:"مصنع العائلة لحامض السلفونيك", paragraph:"العراق."},
    }
   
}