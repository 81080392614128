import React, { useEffect } from 'react'
import Container from '../Container'
import { preset } from '../../TailwindClassPresets/ClassPresets'
import { Field, Form, Formik } from 'formik'
import Spinner from './Spinner'
import SendEmail from '../../Services/EmailServices'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n/config'
import { editStyles, observeElements } from '../../script'
export type FormValuesType = {
  name: string
  lastName: string
  email: string
  message: string
}

export default function ContactUs() {

  async function submitForm(values: FormValuesType){
    console.log(values)
    await SendEmail(values);
  }
  useEffect(()=>{
    observeElements();
    editStyles();
  })
  const {t} = useTranslation();
  return (
    <Container background='bg-navy'>
      <div className='text-white flex flex-col md:flex-row gap-16 justify-between  outOfView'>
        <div className='h-full flex flex-col justify-between max-w-[307px] md:min-h-[432px]'>
          <div>

            <h3 className={`${preset.h3} mb-7`}>{t("shared.contact.us")}</h3>
            <p className='text-base'>{t("shared.contact.paragraph")}</p>

          </div>
          <div className="h-2 w-12 md:h-4 md:w-24 bg-white mt-14"></div>
        </div>

        <div className='w-full max-w-[500px]'>
          <Formik<FormValuesType> initialValues={{name:"", lastName:"", email:"", message:""}} onSubmit={(values)=>submitForm(values)}>
          {(formikProps) => (     
          <Form>
            <div className='space-y-6 flex flex-col items-end w-full pr-2'>
              <div className='flex flex-col w-full'>
                <label className='text-label mr-4'>{t("shared.contact.name")}</label>
                <Field type={"text"} name={"name"} className="w-full h-12 px-4 focus:outline-none text-darkGray" placeholder={t("shared.contact.placeholders.name")}/>
              </div>
              <div className='flex flex-col w-full'>
                <label className='text-label mr-4'>{t("shared.contact.lastName")}</label>
                <Field type={"text"} name={"lastName"} className="w-full h-12 px-4 focus:outline-none text-darkGray" placeholder={t("shared.contact.placeholders.lastName")}/>
              </div>
              <div className='flex flex-col w-full'>
                <label className='text-label mr-4'>{t("shared.contact.email")}</label>
                <Field type={"email"} name={"email"} className="w-full h-12 px-4 focus:outline-none text-darkGray" placeholder={t("shared.contact.placeholders.email")}/>
              </div>
              <div className='flex flex-col w-full'>
                <label className='text-label mr-4'>{t("shared.contact.message")}</label>
                <Field type={"text"} name={"message"} className="w-full h-12 px-4 focus:outline-none py-3 text-darkGray" placeholder={t("shared.contact.placeholders.message")} as="textarea"/>
              </div>

              <div className='pt-1 group'>
                  <button type="submit"
                      disabled={ !formikProps.isValid || !formikProps.dirty  || !formikProps.touched}
                      className={`text-sm font-semibold w-52 h-11 transition-all delay-75 ${i18n.language === 'en' ? "font-['Krona']" : "font-['Tajawal']"} bg-white text-navy cursor-pointer group-hover:bg-darkGray group-hover:text-white`}
                      >
                      {formikProps.isSubmitting ? <Spinner/> :t("shared.contact.submit")}
                  </button>
              </div>

            </div>

          </Form>

          )}
          </Formik>
        </div>

      </div>
    </Container>
  )
}
