import React, { useEffect } from 'react';
import './App.css';
import i18n from './i18n/config';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import Homepage from './Pages/Homepage/Homepage';
import Services from './Pages/Services/Services';
import Navbar from './Components/Navbar/Navbar';
import { NavigationRoutes } from './Components/Navbar/Routes';
import AboutUs from './Pages/AboutUs/AboutUs';
import ContactUs from './Components/ContactUs/ContactUs';
import Footer from './Components/Footer/Footer';
import { observeElements } from './script';
import Projects from './Pages/Projects/Projects';
import PDFDownload from './Components/SharedComponents/PDF/PDFDownload';

function App() {
  
  useEffect(() => {
    observeElements();
  },[]); 

  return (
    <div id='mainapp' className='overflow-hidden' style={{fontFamily: `${i18n.resolvedLanguage === 'en' ? "Jost, sans-serif" : "Tajawal, sans-serif"}`}}>
    {/* <div className='overflow-hidden' style={{fontFamily: `${i18n.language === 'en' ? "Jost, sans-serif" : "Tajawal, sans-serif"}`, direction: `${i18n.language === 'en' ? "ltr" : "rtl"}`}}> */}
      <BrowserRouter>
          <Navbar/>
          <Routes>
            <Route path={NavigationRoutes.Home} element={<Homepage/>}/>
            <Route path={NavigationRoutes.Services} element={<Services/>}/>
            <Route path={NavigationRoutes.AboutUs} element={<AboutUs/>}/>
            <Route path={NavigationRoutes.Projects} element={<Projects/>}/>
            <Route path="/*" element={<Homepage/>}/>
          </Routes>
          <PDFDownload/>
          <ContactUs/>
          <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
