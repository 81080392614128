export const servicesEnglish = {
    engineeringSector:{
        head: "Engineering Sector",
        paragraphs: {
            p0:"Al Juud Group is committed to excellence in the Engineering sector, offering a broad spectrum of services across Civil, Mechanical, Electrical, and Architectural disciplines. Adhering to the highest standards of Safety, Health, Environment, and Quality (SHEQ).",
            p1:"Our engineering services are tailored to meet the dynamic needs of the industry, ensuring each project's success through meticulous planning, execution, and management."
        },
        serviceDetails: {
            service0:{ head: "Pre-Commissioning & Commissioning", paragraph: "Initiation and functional testing of systems and components to ensure they are ready for operation." },
            service1:{ head: "Maintenance & Technical Support", paragraph: "Ongoing and reliable technical support to ensure optimal functioning of systems." },
            service2:{ head: "Steel Fabrication", paragraph: "Expertise in both medium and heavy steel fabrication to cater to diverse industrial needs." },
            service3:{ head: "Water & Waste Water Treatment Plants", paragraph: "State-of-the-art solutions for water and waste water treatment, ensuring environmental compliance." },
            service4:{ head: "Waterproofing & Lining", paragraph: "High-quality waterproofing and lining services for various applications." },
            service5:{ head: "Renewable Energy Solutions", paragraph: "Harnessing wind and solar energy to create sustainable and eco-friendly energy solutions." },
            service6:{ head: "EPC Projects", paragraph: "Full-scale Engineering, Procurement, and Construction projects, ensuring seamless delivery from concept to completion." },
            service7:{ head: "Specialized Construction Services", paragraph: "Construction expertise in Civil, Mechanical, Electrical, and Instrumentation fields." },
            service8:{ head: "Project Lifecycle Management", paragraph: "Comprehensive services including: Tendering, Design, Project Control, Project Supervision, Site Management, On-site supervision, Construction cost analysis, Amendment management, Surveying services." }
        },
    },
    
    
    oilSector:{
        head: "Oil & Gas Sector",
        paragraphs: {
            p0:"Al Juud Group offers comprehensive services in the Oil & Gas Sector, categorized into two main subcategories for optimal clarity and client engagement.",
            p1:"Our team of experts in the Oil & Gas Sector is dedicated to delivering services that uphold the highest standards of quality and safety. Contact us to learn more about how we can assist with your specific needs."
        },
        serviceDetails: {
            service0:{
                head: "Business Services",
                paragraph: {
                    p0:{ title: "Procurement Services", description: "Sourcing and delivering quality materials and equipment for oil & gas sector operations." },
                    p1:{ title: "Trading Services", description: "Expertise in the trade of oil, gas, and related commodities." },
                    p2:{ title: "Agency Services", description: "Representing companies within the oil & gas sector, facilitating business and project development." },
                    p3:{ title: "Consulting Services", description: "Providing expert advice to optimize operations, manage risks, and increase profitability." }
                }
            },
            service1:{
                head: "Technical Services",
                paragraph: {
                    p0:{ title: "Oilfield Equipment Inspection Services", description: "Ensuring the safety and reliability of equipment with thorough inspections." },
                    p1:{ title: "Gas Detection Services", description: "Specialized services for detecting gases, ensuring a safe working environment." },
                }
            }
        },
    },
    
    environmentalSector:{
        head: "Environmental Sector",
        paragraphs: {
            p0:"Al Juud Group proudly upholds a policy rigorously aligned with laws, regulations, and mechanisms concerning environmental protection and sustainability. Al Juud Group also proudly represents international companies that specialize in renewable energy sectors.",
            p1:"Through these efforts, Al Juud Group demonstrates its commitment to environmental stewardship and a sustainable future."
        },
        serviceDetails: {
            service0:{
                head: "Environmental Services",
                paragraph: {
                    p0:{ title: "Waste Management", description: "Implementing strategic solutions for waste collection, treatment, and disposal." },
                    p1:{ title: "Waste Reduction", description: "Advising on and employing methods to minimize waste generation at the source." },
                    p2:{ title: "Energy Use Reduction", description: "Assisting in optimizing energy consumption to enhance efficiency." },
                    p3:{ title: "Alternative Energy Use", description: "Representing companies within the oil & gas sector, facilitating business and project development." },
                    p4:{ title: "Recycling", description: "Encouraging and facilitating the repurposing of materials to extend their lifecycle and reduce environmental impact." }
                }
            },
            service1:{
                head: "Energy Services",
                paragraph: {
                    p0:{ title: "Wind Energy", description: "Offering services and solutions in harnessing wind power." },
                    p1:{ title: "Solar Energy", description: "Providing expertise in solar energy systems, from planning to implementation." },
                }
            }
        },
    },
    
    manufacturingSector :{
        head: "Manufacturing Sector",
        paragraphs: {
            p0:"Al-Aeela Complex for Sulphonation and Detergents",
            p1:"Our state-of-the-art manufacturing facilities are at the heart of our operations. The Al-Aeela complex is a testament to our commitment to industrial excellence, with specialized capabilities in producing high-quality chemical and cleaning products.",
            p2:"At Al Juud Group's manufacturing complex, we focus on delivering products that not only meet but exceed industry standards, ensuring that our clients receive the best in both product quality and service."
        },
        serviceDetails: {
            service0:{ head: "Sulphonic Acid Production", paragraph: "Leveraging advanced processes to produce sulphonic acid, a key ingredient in many cleaning and personal care products." },
            service1:{ head: "Liquid Detergent Manufacturing", paragraph: "Crafting a range of liquid detergents that meet the rigorous demands of hygiene and cleanliness." },
            service2:{ head: "Powder Detergent Production", paragraph: "Formulating high-efficiency powder detergents suitable for a variety of applications." }
        },
    },
    
    generalTradeSector :{
        head: "General Trade Sector",
        paragraphs: {
           p0:"At Al-Juud Group, we leverage our extensive global relationships to offer a diverse array of high-quality products. Our network spans the best origins around the world, enabling us to serve both private and public sectors with unparalleled efficiency and reliability.",
           p1:"Our success in the General Trade Sector is demonstrated by our extensive portfolio of major contracts and the long-term relationships we maintain with key industry players. Contact Al-Juud Group for comprehensive trade solutions that support your business growth."
        },
        serviceDetails: {
            service0:{ head: "Diverse Product Supply", paragraph: "A continuous supply of various products to meet the dynamic needs of our clients." },
            service1:{ head: "Quality Assurance", paragraph: "A commitment to high-quality offerings that satisfy rigorous standards." },
            service2:{ head: "Major Supply Contracts", paragraph: "Skillful management and fulfillment of significant supply contracts." },
            service3:{ head: "Wholesale & Distribution", paragraph: "Robust distribution networks ensuring products reach the marketplace effectively." },
            service4:{ head: "Global Trade", paragraph: "Expertise in import and export operations, handling logistics, customs, and regulations with ease." }
        },
    },
    
    agenciesSector :{
        head: "Agencies Sector",
        paragraphs: {
            p0:"Al-Juud Group prides itself on forming and maintaining strategic partnerships that reinforce our commitment to excellence. As we strive to be the best in what we do, we proudly serve as the official representatives in Iraq for a selection of world-renowned companies across various sectors.",
            p1:"We are dedicated to expanding our partnerships and enhancing our portfolio, ensuring that we continue to offer exceptional services and opportunities to our clients and partners alike. For more information on partnership opportunities, please reach out to Al-Juud Group."
        },
        serviceDetails: {
            service0:{ head: "Exclusive Representation", paragraph: "Acting as the official representative for internationally acclaimed companies in Iraq." },
            service1:{ head: "Diverse Sector Coverage", paragraph: "Partnering with companies specialized in multiple sectors to provide comprehensive solutions." },
            service2:{ head: "Business Expansion", paragraph: "Constantly seeking to broaden our network by establishing new relationships and collaborations." }
        },
    }
}