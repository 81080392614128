import i18n from "i18next";                      
// Bindings for React: allow components to
// re-render when language changes.
import { initReactI18next } from "react-i18next";
import { homepageEnglish } from "../Pages/Homepage/Translations/english";
import { homepageArabic } from "../Pages/Homepage/Translations/arabic";
import { aboutUsEnglish } from "../Pages/AboutUs/Translations/english";
import { aboutUsArabic } from "../Pages/AboutUs/Translations/arabic";
import { sharedEnglishTranslations } from "../Components/SharedComponents/Translations/english";
import { sharedArabicTranslations } from "../Components/SharedComponents/Translations/arabic";
import { servicesEnglish } from "../Pages/Services/Translations/english";
import { servicesArabic } from "../Pages/Services/Translations/arabic";
import { projectsEnglish } from "../Pages/Projects/Translations/english";
import { projectsArabic } from "../Pages/Projects/Translations/arabic";

i18n
  .use(initReactI18next)
  .init({    
    lng: "en",
    fallbackLng: "ar",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      // English
      en: { translation: {shared:sharedEnglishTranslations, homepage:homepageEnglish, about:aboutUsEnglish, services:servicesEnglish, projects:projectsEnglish}},
      // Arabic
      ar: { translation: {shared:sharedArabicTranslations, homepage:homepageArabic, about:aboutUsArabic, services:servicesArabic, projects:projectsArabic}},
    },
  });

export default i18n;