export const aboutUsArabic = {
    aboutUs: {
        title: "حولنا",
        paragraph: "تأسست مجموعة شركات الجود في أوائل عام 1992 بإنشاء مجمع مصانع العائلة وعلى مر السنين وبناءً على التزامنا بتلبية توقعات عملائنا، نمت المجموعة بثبات من خلال إنشاء شركات وأقسام جديدة لتغطية مطالب عملائنا، ومن هذه البدايات توسعنا على نطاق واسع من خلال تقديم خدمة عالية الجودة ومنذ ذلك الحين تظهر تطوراتنا على مر السنين. مجموعة شركات الجود متعددة الأوجه مع عمليات في مجالات مختلفة تمامًا. أنشطتنا تشمل مشاريع كبيرة في قطاع الهندسة التي تغطي (المدنية، الميكانيكية، الكهربائية والمعمارية)، قطاع النفط والغاز، القطاع البيئي، القطاع التصنيعي، قطاع التجارة العامة وقطاع الوكالات. لقد وضعت مجموعتنا مبادئ في تجارتنا، العقود لا تنتهي عند وقت التسليم، بل نضمن رضا عملائنا عن المنتجات التي حصلوا عليها من خلال تقديم خدمات ما بعد البيع، وهذا ما يميز المجموعة عن منافسيها ويوفر الفرصة لخلق علاقات طويلة الأمد مع عملائنا."
    },
    ceo:{
        title: "رسالة الرئيس التنفيذي",
        letter: {
            first: "عزيزي العميل والشركاء،",
            second:"نيابة عن مجموعة شركات الجود، أود أن أشكركم على اختيار مجموعتنا لتكون وجهتكم للحصول على حلول مشاريعكم والمنتجات المناسبة. أستطيع أن أقول لكم أننا متحمسون وملهمون بالفرص التي تمنحونا إياها لنكون شركاءكم في تنفيذ مشاريعكم. تركيزنا الرئيسي هو معرفة مطالب عملائنا ومعاملتهم كشركاء وأصدقاء. على مر السنين، بقينا ملتزمين بتطوير أفرادنا، تقوية قدراتنا، وبناء علاقات ثقة مع عملائنا وشركائنا، وهذا هو أفضل استثمار نقوم به كل يوم. نأمل أن تجدوا فينا المحطة الواحدة التي تبحثون عنها لحلول تقع ضمن نطاق كفاءات شركاتنا. المبادئ والمعايير التي نتبعها جعلت مجموعتنا واحدة من أكثر المجموعات سمعةً محليًا وعالميًا. نحن متحمسون للمستقبل ونتطلع إلى الأفضل.",
            third: "مع خالص التقدير،",
            forth: "سيف الحاني"
        }
    },
    mv:{
        title: "مهمتنا و رؤيتنا",
        mission:{
            title:"مهمتنا",
            paragraph:"تتمثل مهمتنا في مجموعة شركات الجود في السعي بلا هوادة إلى تحقيق التميز في كل جانب من جوانب عملياتنا، مسترشدين بالتزامنا الثابت تجاه عملائنا وشركائنا ومجتمعاتنا. نحن نطمح إلى أن نكون أكثر من مجرد مزود للحلول؛ نحن نهدف إلى أن نكون مستشارين موثوقين وحلفاء يمكن الاعتماد عليهم في رحلة عملائنا نحو النجاح."
        },
        vision:{
            title: "رؤيتنا",
            paragraph: "نعتقد أن النجاح يأتي من التعاون ونضع موارد مجموعتنا في خدمة عملائنا وشركائنا، من أجل خلق قيمة طويلة الأمد لأصحاب المصلحة لدينا، نحن فخورون بتاريخنا ونتطلع إلى مستقبل مشرق لنكون الرائد المعترف به في مجالنا من حيث الجودة والأداء."
        }
    },
    org: "مجموعتنا",
    group: "مجموعة الجود",
    wahat: "شركة واحة الجود للمقاولات العامة والتجارة.",
    diwan: "شركة ديوان الجود للتجارة العامة والمقاولات والوكالات التجارية.",
    ramz: "شركة رمز الجود للتجارة العامة.",
    aila:"مصنع العائلة للسلفوكيماويات",
    hani: "مكتب سيف الحاني للاستيراد والتصدير"
}