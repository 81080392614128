import React, { useEffect, useState } from 'react'
import { preset } from '../../TailwindClassPresets/ClassPresets'
import engImg1 from "../../Images/EngServices/eng1.jpg"
import engImg2 from "../../Images/EngServices/eng9.jpg"
import engImg3 from "../../Images/EngServices/eng8.jpg"

import oilImg1 from "../../Images/OilServices/oil5.jpg"
import oilImg2 from "../../Images/OilServices/oil2.jpg"
import oilImg3 from "../../Images/OilServices/oil6.jpg"

import manImg1 from "../../Images/ManServices/man5.jpg"
import manImg2 from "../../Images/ManServices/man1.jpg"
import manImg3 from "../../Images/ManServices/man2.jpg"

import envImg1 from "../../Images/EnvServices/env2.jpg"
import envImg2 from "../../Images/EnvServices/env5.jpg"
import envImg3 from "../../Images/EnvServices/env3.jpg"

import genImg1 from "../../Images/GenServices/gen6.jpg"
import genImg2 from "../../Images/GenServices/gen7.jpg"
import genImg3 from "../../Images/GenServices/gen9.jpg"

import agcImg1 from "../../Images/AgcServices/agc1.jpg"
import agcImg2 from "../../Images/AgcServices/agc3.jpg"
import agcImg3 from "../../Images/AgcServices/agc5.jpg"

import { useTranslation } from 'react-i18next'

export default function NewHero() {
    const {t} = useTranslation();
    const slides =[
        {id:"slide0", text:t("homepage.hero.slide0.text"), subText:t("homepage.hero.slide0.subText"), tabText:t("homepage.hero.slide0.tabText"), backgrounds: [engImg1, engImg2, engImg3]},
        {id:"slide1", text:t("homepage.hero.slide1.text"), subText:t("homepage.hero.slide1.subText"), tabText:t("homepage.hero.slide1.tabText"), backgrounds: [oilImg1, oilImg2, oilImg3]},
        {id:"slide2", text:t("homepage.hero.slide2.text"), subText:t("homepage.hero.slide2.subText"), tabText:t("homepage.hero.slide2.tabText"), backgrounds: [envImg1, envImg2, envImg3]},
        {id:"slide3", text:t("homepage.hero.slide3.text"), subText:t("homepage.hero.slide3.subText"), tabText:t("homepage.hero.slide3.tabText"), backgrounds: [manImg1, manImg2, manImg3]},
        {id:"slide4", text:t("homepage.hero.slide4.text"), subText:t("homepage.hero.slide4.subText"), tabText:t("homepage.hero.slide4.tabText"), backgrounds: [genImg1, genImg2, genImg3]},
        {id:"slide5", text:t("homepage.hero.slide5.text"), subText:t("homepage.hero.slide5.subText"), tabText:t("homepage.hero.slide5.tabText"), backgrounds: [agcImg1, agcImg2, agcImg3]},
    ];
    // const images = [engImg1, oilImg2, envImg, manImg, genImg, agcImg]

    const [currentSlide, setCurrentSlide] = useState(slides[0]);

  
    const timeoutIds:any = [];
    const terminateTimeouts = () => {
        timeoutIds.forEach((timeoutId:any) => clearTimeout(timeoutId));
    };
    useEffect(()=>{
        const hero = document.getElementById("heroDiv");
        const slide = document.getElementById(currentSlide.id);
        function changeAndAnimateText(element: HTMLElement | null, text: string){
            if (element) {
                element.style.transform = "translateY(100%)";
                element.style.opacity = "0"
                element.innerText = text;
                setTimeout(() => {
                    element.style.transform = "translateY(0%)";
                    element.style.opacity = "1"
                }, 500);
            }
        }
        if (slide) {
            changeAndAnimateText(document.getElementById("heroh2"), currentSlide.text);
            changeAndAnimateText(document.getElementById("herop"), currentSlide.subText);

            
            slide.addEventListener("animationend", animationEndHandler)
            if(hero) {
                for (let i =0; i<= currentSlide.backgrounds.length*2; i++){
                    const timeoutId = setTimeout(()=>{
                        hero.style.backgroundImage = `url(${currentSlide.backgrounds[i%currentSlide.backgrounds.length]})`
                    }, i * 5000)
                    timeoutIds.push(timeoutId)
                }
                // hero.style.backgroundImage = `url(${currentSlide.background})`
            }

            slide.style.animation = "fillAndEmpty 15s forwards";

        }

        function animationEndHandler(){
            terminateTimeouts()
            if(slide){
                slide.removeEventListener("animationend", animationEndHandler)
                slide.style.animation = "";
            }
            const currentIndex = slides.findIndex(slide => slide.id === currentSlide.id);
            const nextIndex = (currentIndex + 1) % slides.length;

            setCurrentSlide(slides[nextIndex])
        }
    
    },[currentSlide, setCurrentSlide])
    


    function ChangeSlide(event:any){
        terminateTimeouts();
        const slide = document.getElementById(currentSlide.id);
        if(slide) slide.style.animation = ""
        setCurrentSlide(event);
    }

    function pauseAnimation(slide: any) {
        const progressBar = document.getElementById(slide.id);
        if(progressBar) progressBar.style.animationPlayState = 'paused';
    }
    
    function resumeAnimation(slide: any) {
        const progressBar = document.getElementById(slide.id);
        if(progressBar) progressBar.style.animationPlayState = 'running';
    }

    return (
        <div id='heroDiv' className='w-full h-[80vh] flex flex-col items-center justify-center py-12 transition-all duration-75 ease-linear relative' 
            style={{backgroundImage:`url(${slides[0].backgrounds[0]})`, backgroundRepeat:'no-repeat', backgroundSize:"cover", backgroundPosition:"center center"}}
        >
            <div id='heroGrad' className="absolute blackToTransparent w-screen h-[80vh]">

            </div>
            <div className=' w-full max-w-[1280px] h-full flex flex-col items-start justify-center px-6'>

                <div className='flex flex-col gap-5 text-white'>
                    <p id='herop' className=' transition-all duration-500'></p>
                    <h2 id='heroh2' className={`${preset.h3} textFewww max-w-[790px] transition-all duration-500`}></h2>
                    {/* <button className='w-fit '><span id='herospan' className='transition-all duration-500'></span></button> */}
                </div>       
            </div>
            <div className='flex items-end gap-2 md:gap-5 w-full max-w-[1300px] px-4 z-30'>
                
                {
                    slides.map((slide)=>(
                        // <div id={`${slide.id}gp`} className="w-full grandParent" onClick={(e) => playAnimation(e)}>
                        <div key={slide.id} id={`${slide.id}gp`} className={`cursor-pointer ${currentSlide.id === slide.id ? "w-full grow": "w-10 md:w-full shrink"}`} 
                            onClick={() => ChangeSlide(slide)} 
                            onMouseEnter={() => pauseAnimation(slide)} 
                            onMouseLeave={() => resumeAnimation(slide)}
                        >
                            <h6 className={`${preset.h6} text-white mb-4 hidden md:block`}>{slide.tabText}</h6>
                            <div className={`loading-bar md:max-w-[300px] h-1 relative overflow-x-hidden transition-all duration-200 ease-linear rounded-full bg-navy bg-opacity-50 ${currentSlide.id === slide.id ? "w-full grow": "w-8 md:w-full"}`}>
                                <div id={`${slide.id}`} className="progress absolute h-1 w-0 bg-white rounded-xl"></div>
                            </div>
                        </div>     
                    ))
                }

            </div>
        </div>
    )
}