import React, { useEffect, useRef, useState } from 'react'
import logo from "../../Images/logo.png"
import { Link } from 'react-router-dom'
import { NavigationRoutes } from './Routes'
import xMark from "../../Icons/xmark.svg"
import hamburger from "../../Icons/hamburger.svg"
import HamburgerMenu from './HamburgerMenu'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from '../LanguageSwitcher'

export default function Navbar() {

    const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

    const toggleMenu = () => {setIsHamburgerMenuOpen(!isHamburgerMenuOpen);};

    useEffect(() => {
        // Function to handle scroll event
        function handleScroll() {
            const scrollY = window.scrollY || window.pageYOffset;
            const nav = document.getElementById("navbar");
    
            if (scrollY >= 120 && nav) {
                // Fix the position of the navbar
                nav.style.position = "fixed";
                nav.classList.add("shadow-xl")
            } else if (nav) {
                // Reset the position of the navbar
                nav.style.position = "static";
                nav.classList.remove("shadow-xl")

            }
        }
    
        // Add event listener for scroll
        window.addEventListener('scroll', handleScroll);
    
        // Clean up by removing the event listener when component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    

    const {t} = useTranslation();
  return (
  <>
    <nav id='navbar' className='w-screen flex bg-white z-40 justify-center mx-auto h-20 md:h-28' style={{direction:"ltr"}}>
        <div className='flex justify-between items-center w-full md:max-w-[1232px] px-2 mx-3'>

            <Link to={NavigationRoutes.Home} className='flex flex-col justify-center items-center'>
                <img alt='Logo' src={logo} className='w-10 md:w-14 h-12 md:h-16'/>
                <p className='text-[#999999] text-xs md:text-sm font-semibold fontSwitcher'>{t('shared.name')}</p>
            </Link>
            <div className='flex gap-10'>
                <ul id='navbarlist' className='md:flex gap-10 hidden'>
                    <li className='hover:underline underline-offset-8'><Link to={NavigationRoutes.Home}>{t("shared.navbar.home")}</Link></li>
                    <li className='hover:underline underline-offset-8'><Link to={NavigationRoutes.AboutUs}>{t("shared.navbar.about")}</Link></li>
                    <li className='hover:underline underline-offset-8'><Link to={NavigationRoutes.Services}>{t("shared.navbar.services")}</Link></li>
                    <li className='hover:underline underline-offset-8'><Link to={NavigationRoutes.Projects}>{t("shared.navbar.projects")}</Link></li>
                </ul>
                <div className='hidden md:inline hover:underline underline-offset-8'><LanguageSwitcher/></div>
            </div>

            {/* <button onClick={toggleMenu} className='size-12 md:hidden z-50 bg-white'>
                {
                    // isHamburgerMenuOpen ? (<img alt='xMark' src={xMark}/>) : (<img alt='hamburger' src={hamburger}/>)
                }
                <label className="burger" htmlFor="burger">
                    <input type="checkbox" checked={isHamburgerMenuOpen} id="burger"/>
                    <span></span>
                    <span></span>
                    <span></span>
                </label>
            </button> */}
            <button onClick={toggleMenu} className='size-12 md:hidden z-50 bg-white flex flex-col gap-1 items-center justify-center'>
                <span className={`w-8 h-[3px] rounded-3xl transition-all duration-150 ease-in-out bg-darkGray ${isHamburgerMenuOpen? "rotate-45 -translate-y-px" : ""}`}></span>
                <span className={`w-8 h-[3px] rounded-3xl transition-all duration-150 bg-darkGray ${isHamburgerMenuOpen? "hidden" : ""}`}></span>
                <span className={`w-8 h-[3px] rounded-3xl transition-all duration-150 ease-in-out bg-darkGray ${isHamburgerMenuOpen? "-rotate-45 -translate-y-2" : ""}`}></span>
            </button>
            <HamburgerMenu open={isHamburgerMenuOpen} isMenuOpen={isHamburgerMenuOpen} setIsMenuOpen={setIsHamburgerMenuOpen}/>
        </div>
    </nav>
  </>

  )
}
