import React from 'react'
import Container from '../../Components/Container';
import { preset } from '../../TailwindClassPresets/ClassPresets';
import ServiceDetail from '../../Components/Services/ServiceDetail';
import SloganWhite from "../../Images/SloganWhite.png"
import SwiperContainer from '../../Components/SwiperContainer';


export type SectorData = {
    head: string;
    paragraphs: string[];
    serviceDetails: {head:string; paragraph:string | {title:string; description:string}[]}[];
    photos: string[]
}

export default function SectorTemplate({data}: {data: SectorData}) {
  return (
    <>
        <Container background='bg-white'>
            <div className='size-full flex flex-col justify-between relative gap-10 outOfView'>
                <div className='relative w-full flex justify-between items-start z-20'>
                    <div>
                        <img className='size-20 md:hidden mb-4' src={SloganWhite} alt='Star'></img>
                        <h2 className={`${preset.special} font-extrabold text-navy`}>{data.head}</h2>
                    </div>
                    <img className='hidden md:block size-32' src={SloganWhite} alt='Star'></img>

                    {/* <div className='hidden md:block size-32 mr-6 '></div> */}
                </div>
                
                {data.photos.length >= 3 && (
                    <div className="flex gap-8 justify-center lg:justify-between select-none">
                        <div className="hidden lg:block w-[812px] z-10 self-center h-fit">
                            <SwiperContainer
                                photos={data.photos.slice(0, -2)}
                            />
                        </div>
                        <div className=" w-[300px] md:w-[700px] lg:hidden z-10 self-center">
                            <SwiperContainer
                                photos={data.photos}
                            />
                        </div>

                        <div className='flex-col gap-8 hidden lg:flex'>
                            <img alt='Carousel' className='h-[245px] w-full object-cover' src={data.photos[data.photos.length - 2]}/>
                            <img alt='Carousel' className='h-[248px] w-full object-cover' src={data.photos[data.photos.length - 1]}/>
                        </div>
                    </div>
                )}


                <div className='flex items-end justify-between'>

                    <div className='z-30'>
                        {
                            data.paragraphs.map((paragraph)=>(
                                <p className='text- max-w-[400px] mt-3 first:mt-0 z-30 outleene '>{paragraph}</p>
                            ))
                        }
                    </div>

                    <span className='order-last md:order-none h-2 w-12 md:h-4 md:w-24  bg-navy'></span>
                </div>

                {/* <div className='block md:absolute left-0 top-36 w-[300px] md:w-[500px] md:max-h-[400px] z-10'>
                    <SwiperContainer photos={data.photos}/>
                </div> */}
            </div>
        </Container>
        <Container background='bg-navy'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-2 place-content-evenly'>
                {
                     data.serviceDetails.map((details)=>(
                        <ServiceDetail text={details}/>
                    ))
                }
            </div>
        </Container>
    </>
  )
}

