import React, { useEffect } from 'react'
import Hero from './Hero'
import OurServices from './OurServices'
import GlobalMap from './GlobalMap'
import PartnersContractors from './PartnersContractors'
import Container from '../../Components/Container'
// import SwiperSlider from '../../Components/Swiper'
// import logo from "../../Images/logo.png"
import { editStyles, observeElements } from '../../script';
import Projects from './ProjectsSection'
import i18n from '../../i18n/config'
import NewHero from './NewHero'

export default function Homepage() {
  useEffect(()=>{
    window.scrollTo(0, 0);
    observeElements();
    editStyles();
  },[])
  return (
    <>
      <NewHero/>
      {/* <Hero/> */}
	    <OurServices/>
      <PartnersContractors/>
      <Projects/>
      <GlobalMap/>
    </>
  )
}
