import React from 'react'
import Container from '../../Components/Container'
import { preset } from '../../TailwindClassPresets/ClassPresets'
import arcoma from "../../Images/Partners/arcoma.png" 
import arvOilGas from "../../Images/Partners/ARVOilGas.png" 
import baghdad from "../../Images/Partners/Baghdad.jpg" 
import caterPillar from "../../Images/Partners/CATerPillar.png" 
import electricity from "../../Images/Partners/electricity.png" 
import industry from "../../Images/Partners/industry.jpg" 
import karkuk from "../../Images/Partners/Karkuk.png" 
import pact from "../../Images/Partners/PACT.jpg" 
import mayoraltyOfbaghdad from "../../Images/Partners/MayoraltyOfBaghdad.jpg" 
import oil from "../../Images/Partners/oil.jpg" 
import sCDPI from "../../Images/Partners/SCDPI.png" 
import sewers from "../../Images/Partners/Sewers.png" 
import texasAquaticHarvesting from "../../Images/Partners/texasAquaticHarvesting.png" 
import wasat from "../../Images/Partners/Wasat.jpg" 
import water from "../../Images/Partners/water.jpg" 
import ameer from "../../Images/Partners/ameer.jpg" 
import assa from "../../Images/Partners/assa.png" 
import icrc from "../../Images/Partners/icrc.png" 
import terre from "../../Images/Partners/terre.jpg" 
import prinz from "../../Images/Partners/prinz.jpg" 
import qaswaa from "../../Images/Partners/qaswaa.jpeg" 
import seko from "../../Images/Partners/seko.jpg" 
import nrc from "../../Images/Partners/nrc.png" 
import mitas from "../../Images/Partners/mitas.png" 
import { useTranslation } from 'react-i18next'

export default function PartnersContractors() {
    // const contractors = [arvOilGas, baghdad, caterPillar, electricity, industry, karkuk, sewers]
    // const partners = [mayoraltyOfbaghdad, oil, sCDPI, texasAquaticHarvesting, wasat, water, pact]
    const {t} = useTranslation();
  return (
    <>
        <Container background='bg-white'>
            <div className='outOfView'>
                <div className="flex justify-between">
                    <p className={`${preset.h3} text-navy mb-8 font-extrabold`}>{t("homepage.partners.ourContractors")}</p>
                </div>
                {/* <div className="flex gap-8 items-center justify-center flex-wrap"> */}
                <div className="grid gap-8 grid-cols-2 md:grid-cols-4 place-items-center">
                    {/* This is stupid, i know, but it is easier, not in the mood to create objects so i can have different sizes c: */}
                    <img loading='lazy' className='col-span-2 md:col-span-1 h-32 object-cover' src={industry} alt="logo" />
                    <img loading='lazy' className='col-span-2 md:col-span-1 h-24 object-cover' src={sCDPI} alt="logo" />
                    <img loading='lazy' className='md:col-span-1 h-24 object-cover' src={baghdad} alt="logo" />
                    <img loading='lazy' className='md:col-span-1 h-24 object-cover pr-3 md:p-0' src={electricity} alt="logo" />
                    <img loading='lazy' className='md:col-span-1 h-[105px] object-cover pl-4 md:p-0' src={mayoraltyOfbaghdad} alt="logo" />
                    <img loading='lazy' className='md:col-span-1 h-32 object-cover' src={oil} alt="logo" />
                    <img loading='lazy' className='md:col-span-1 h-32 object-cover' src={water} alt="logo" />
                    <img loading='lazy' className='md:col-span-1 h-28 object-cover' src={sewers} alt="logo" />
                    <img loading='lazy' className='md:col-span-1 h-28 object-cover' src={wasat} alt="logo" />
                    <img loading='lazy' className='md:col-span-1 h-28 object-cover' src={karkuk} alt="logo" />
                    <img loading='lazy' className='col-span-2 md:col-span-1 h-16 object-cover' src={arvOilGas} alt="logo" />
                    <img loading='lazy' className='md:col-span-1 h-[105px] object-cover rounded-full' src={qaswaa} alt="logo" />
                    <img loading='lazy' className='md:col-span-1 h-28 object-cover' src={nrc} alt="logo" />
                    <img loading='lazy' className='md:col-span-1 h-28 object-cover' src={icrc} alt="logo" />
                    <img loading='lazy' className='md:col-span-1 h-24 object-cover' src={caterPillar} alt="logo" />
                    <img loading='lazy' className='col-span-2 md:col-span-1 h-20 object-cover' src={pact} alt="logo" />
                    <img loading='lazy' className='col-span-2 md:col-span-1 h-16 object-cover' src={arcoma} alt="logo" />
                    <img loading='lazy' className='md:col-span-1 h-20 object-cover' src={texasAquaticHarvesting} alt="logo" />
                    <img loading='lazy' className='md:col-span-1 h-28 object-cover' src={ameer} alt="logo" />
                    <img loading='lazy' className='col-span-2 md:col-span-1 h-14 object-cover' src={assa} alt="logo" />
                    <img loading='lazy' className='col-span-2 md:col-span-1 h-20 object-cover' src={terre} alt="logo" />
                    <img loading='lazy' className='col-span-2 md:col-span-1 h-16 object-cover' src={prinz} alt="logo" />
                    <img loading='lazy' className='col-span-2 md:col-span-1 h-14 object-cover' src={seko} alt="logo" />
                    <img loading='lazy' className='col-span-2 md:col-span-1 h-12 object-cover' src={mitas} alt="logo" />
                    {/* {
                        contractors.map((logo, index)=>(
                            <img className='h-40 object-cover' key={index} src={logo} alt="logo" />
                        ))
                    } */}
                </div>
                <div className="flex w-full justify-end">
                    <div className="block md:h-4 md:w-24 h-2 w-12 bg-navy mt-10 "></div>
                </div>

            </div>
            {/* <div className='outOfView flex flex-col'>
                <p className={`${preset.h3} self-end text-navy mt-20 mb-8 font-extrabold`}>{t("homepage.partners.ourPartners")}</p>
                <div className="flex gap-8 items-center justify-center flex-wrap">
                    <img className='h-20 object-cover ' src={arvOilGas} alt="logo" />
                    <img className='h-24 object-cover' src={caterPillar} alt="logo" />
                    <img className='h-24 object-cover' src={pact} alt="logo" />
                    <img className='h-20 object-cover' src={arcoma} alt="logo" />
                    <img className='h-32 object-cover' src={sCDPI} alt="logo" />
                    <img className='h-24 object-cover' src={texasAquaticHarvesting} alt="logo" />
                    {
                        partners.map((logo, index)=>(
                            <img className='h-32 object-cover' key={index} src={logo} alt="logo" />
                        ))
                    }
                </div>
                <div className="block md:h-4 md:w-24 h-2 w-12 bg-navy mt-10"></div>

            </div> */}
        </Container>
    </>
  )
}
