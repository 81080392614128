import React from 'react'
import { VectorMap } from '@react-jvectormap/core';
import { worldMill } from '@react-jvectormap/world';
import { useRef } from "react";
import Container from '../../Components/Container';
import { preset } from '../../TailwindClassPresets/ClassPresets';
import { useTranslation } from 'react-i18next';

export default function GlobalMap() {
    const gdpData = {
        DE: 5000,
        SE: 3000,
        IQ: 10000,
        AE: 3000,
        JO: 3000,
        US: 3000
    };
    const mapRef = useRef();
    const {t} = useTranslation();

    const handleMarkerClick = (event, index) => {
        // Retrieve the coordinates of the clicked marker
        const clickedMarker = markers[index];
        const lat = clickedMarker.latLng[0];
        const lng = clickedMarker.latLng[1];
        // Navigate user to Google Maps for directions
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`, '_blank');
      };
    
      // Define markers array
      const markers = [
        {
          latLng: [33.303864, 44.371994],
          name: "AL-Juud Group Location"
        }
      ];

    return (
        <Container background='white'>
            <p className={`${preset.h3} font-extrabold mb-12 text-navy outOfView`}>{t("homepage.global")}</p>
            <div className='md:h-[75vh] outOfView' style={{ width: "100%"}}>
            <VectorMap
          ref={mapRef}
          zoomOnScroll={false}
          zoomButtons={false}
          map={worldMill}
          backgroundColor="white"
          containerStyle={{
            width: "100%",
            height: "100%"
          }}
          markerStyle={{
            initial: {
              fill: "yellow",
              stroke: "#383f47"
            }
          }}
          containerClassName="map"
          series={{
            regions: [
              {
                scale: ["#DEEBF7", "#1c2e61"],
                attribute: "fill",
                values: gdpData,
                normalizeFunction: "polynomial",
                min: 1,
                max: 2050
              }
            ]
          }}
          markers={markers}
          regionStyle={{
            initial: {
              fill: "#D1D5DB",
              "fill-opacity": 1,
              stroke: "#265cff",
              "stroke-width": 0,
              "stroke-opacity": 0
            },
            selected: {
              fill: "#FFFB00"
            }
          }}
          // Attach onClick event handler to handle marker click
          onMarkerClick={handleMarkerClick}
          onRegionTipShow={function (event, label, code) {
            if (gdpData[code] === undefined) {
              label.html(
                '<div style="background-color: white; border: 1px solid white; outline: 10px solid white; border-radius: 6px; min-height: 70px; width: 150px; color: black; padding: 10px; text-align: center;">' +
                '<p><b>' + label.html() + '</b></p>' +
                '<p>We are not available here.</p>' +
                '</div>'
              );
            } else {
              label.html(
                '<div style="background-color: white; border: 1px solid white; outline: 10px solid white; border-radius: 6px; min-height: 70px; width: 150px; color: black; padding: 10px; text-align: center;">' +
                '<p><b>' + label.html() + '</b></p>' +
                '<p>We are present here.</p>' +
                '</div>'
              );
            }
          }}
        />
            </div>
            <div className="block md:h-4 md:w-24 h-2 w-12 bg-navy mt-10  outOfView"></div>
        </Container>
    )
}
