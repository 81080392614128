export const servicesArabic = {
    engineeringSector:{
        head: "القطاع الهندسي",
        paragraphs: {
            p0:"تلتزم مجموعة الجود بالتميز في القطاع الهندسي، حيث تقدم مجموعة واسعة من الخدمات في التخصصات المدنية والميكانيكية والكهربائية والمعمارية. الالتزام بأعلى معايير السلامة والصحة والبيئة والجودة (SHEQ).",
            p1:"خدماتنا الهندسية مصممة لتلبية احتياجات الصناعة المتغيرة، مع ضمان نجاح كل مشروع من خلال التخطيط الدقيق والتنفيذ والإدارة."
        },
        serviceDetails: {
            service0:{ head: "خدمات ما قبل التشغيل والتكليف", paragraph: "بدء واختبار الوظائف للأنظمة والمكونات للتأكد من جاهزيتها للتشغيل." },
            service1:{ head: "الصيانة والدعم الفني", paragraph: "دعم فني مستمر وموثوق لضمان الأداء الأمثل للأنظمة." },
            service2:{ head: "تصنيع الفولاذ", paragraph: "خبرة في تصاميم مقاطع الفولاذ المتوسط والثقيل وتصنيعها وتلبية الاحتياجات الصناعية المتنوعة." },
            service3:{ head: "محطات معالجة المياه ومياه الصرف الصحي", paragraph: "حلول متطورة لمعالجة المياه ومياه الصرف الصحي، مع ضمان الالتزام بالمعايير البيئية." },
            service4:{ head: "العزل المائي والبطانة", paragraph: "خدمات عزل مائي وبطانة عالية الجودة لتطبيقات متنوعة." },
            service5:{ head: "حلول الطاقة المتجددة", paragraph: "استغلال طاقة الرياح والطاقة الشمسية لخلق حلول طاقة مستدامة وصديقة للبيئة." },
            service6:{ head: "مشاريع EPC", paragraph: "مشاريع الهندسة والتوريد والبناء على نطاق كامل، مع ضمان التسليم السلس من الفكرة إلى الإنجاز." },
            service7:{ head: "خدمات البناء المتخصصة", paragraph: "خبرة في البناء في مجالات الهندسة المدنية والميكانيكية والكهربائية والتحكم." },
            service8:{ head: "إدارة دورة حياة المشروع", paragraph: "خدمات شاملة تشمل: المناقصات، التصميم، مراقبة المشاريع، الإشراف على المشاريع، إدارة الموقع، الإشراف في الموقع، تحليل تكاليف البناء، إدارة التعديلات، خدمات المسح" }
        },
    },
    
    
    oilSector:{
        head: "قطاع النفط",
        paragraphs: {
            p0:"تقدم مجموعة الجود خدمات شاملة في قطاع النفط، مقسمة إلى فئتين فرعيتين رئيسيتين لتحقيق الوضوح الأمثل ومشاركة العملاء.",
            p1:"فريقنا من الخبراء في قطاع النفط مكرس لتقديم الخدمات التي تدعم أعلى معايير الجودة والسلامة. اتصل بنا لمعرفة المزيد حول كيف يمكننا مساعدتك في تلبية احتياجاتك الخاصة."
        },
        serviceDetails: {
            service0:{
                head: "الخدمات التجارية",
                paragraph: {
                    p0:{ title: "خدمة التوريد", description: "توفير وتسليم مواد ومعدات ذات جودة عالية لعمليات قطاع النفط." },
                    p1:{ title: "خدمة التجارة", description: "خبرة في تجارة النفط والغاز والسلع ذات الصلة." },
                    p2:{ title: "خدمة الوكالة", description: "تمثيل الشركات ضمن قطاع النفط، وتسهيل تطوير الأعمال والمشاريع." },
                    p3:{ title: "خدمة الاستشارات", description: "تقديم المشورة الخبيرة لتحسين العمليات، وإدارة المخاطر، وزيادة الربحية" }
                }
            },
            service1:{
                head: "الخدمات الفنية",
                paragraph: {
                    p0:{ title: "خدمة فحص معدات حقول النفط", description: "ضمان سلامة وموثوقية المعدات من خلال فحوصات شاملة." },
                    p1:{ title: "خدمات الكشف عن الغازات", description: "خدمات متخصصة للكشف عن الغازات، مما يضمن بيئة عمل آمنة." },
                }
            }
        },
    },
    
    environmentalSector:{
        head: "القطاع البيئي",
        paragraphs: {
            p0:"تفتخر مجموعة الجود بسياسة تتماشى بشكل صارم مع القوانين واللوائح والآليات المتعلقة بحماية البيئة واستدامتها. كما تمثل مجموعة الجود بكل فخر الشركات العالمية المتخصصة في قطاعات الطاقة المتجددة.",
            p1:"ومن خلال هذه الجهود، تثبت مجموعة الجود التزامها بالرعاية البيئية والمستقبل المستدام."
        },
        serviceDetails: {
            service0:{
                head: "خدمات البيئية",
                paragraph: {
                    p0:{ title: "إدارة النفايات", description: "تنفيذ حلول استراتيجية لجمع النفايات، ومعالجتها، والتخلص منها." },
                    p1:{ title: "تقليل النفايات", description: "الاستشارة في وتطبيق طرق لتقليل توليد النفايات من المصدر." },
                    p2:{ title: "تقليل استهلاك الطاقة", description: "المساعدة في تحسين استهلاك الطاقة لزيادة الكفاءة." },
                    p3:{ title: "استخدام الطاقة البديلة", description: "تعزيز استخدام مصادر الطاقة المتجددة لتقليل الاعتماد على الوقود الأحفوري." },
                    p4:{ title: "إعادة التدوير", description: "تشجيع وتسهيل إعادة استخدام المواد لإطالة دورة حياتها وتقليل الأثر البيئي." }
                }
            },
            service1:{
                head: "خدمات الطاقة",
                paragraph: {
                    p0:{ title: "طاقة الرياح", description: "تقديم الخدمات والحلول في استغلال طاقة الرياح." },
                    p1:{ title: "طاقة شمسية", description: "تقديم الخبرة في أنظمة الطاقة الشمسية، من التخطيط إلى التنفيذ." },
                }
            }
        },
    },
    
    manufacturingSector :{
        head: "القطاع التصنيعي",
        paragraphs: {
            p0:"مجمع العائلة للسلفنة والمنظفات",
            p1:"تقع مرافق التصنيع الحديثة لدينا في قلب عملياتنا. مجمع العائلة هو شهادة على التزامنا بالتميز الصناعي، مع قدرات متخصصة في إنتاج المنتجات الكيميائية والتنظيفية عالية الجودة.",
            p2:"في مجمع التصنيع التابع لمجموعة الجود، نركز على تقديم المنتجات التي لا تلبي معايير الصناعة فحسب، بل تتجاوزها، مما يضمن حصول عملائنا على الأفضل من حيث جودة المنتج والخدمة.."
        },
        serviceDetails: {
            service0:{ head: "إنتاج حمض السلفونيك", paragraph: "استغلال العمليات المتقدمة لإنتاج حمض السلفونيك، المكون الرئيسي في العديد من منتجات التنظيف والعناية الشخصية." },
            service1:{ head: "تصنيع المنظفات السائلة", paragraph: "صنع مجموعة من المنظفات السائلة التي تلبي المطالب الصارمة للنظافة والصحة." },
            service2:{ head: "إنتاج المنظفات البودرة", paragraph: "تكوين المنظفات البودرة عالية الكفاءة التي تناسب مجموعة متنوعة من التطبيقات." }
        },
    },
    
    generalTradeSector :{
        head: "قطاع التجارة العامة",
        paragraphs: {
            p0:"في مجموعة الجود، نستفيد من علاقاتنا العالمية الواسعة لتقديم مجموعة متنوعة من المنتجات عالية الجودة. تمتد شبكتنا إلى أفضل الأصول حول العالم، مما يمكننا من خدمة القطاعين العام والخاص بكفاءة وموثوقية لا مثيل لها.",
            p1:"يتجلى نجاحنا في قطاع التجارة العامة من خلال مجموعتنا الواسعة من العقود الكبرى والعلاقات طويلة الأمد التي نحافظ عليها مع اللاعبين الرئيسيين في الصناعة. تواصل مع مجموعة الجود للحصول على حلول تجارية شاملة تدعم نمو أعمالك."
        },
        serviceDetails: {
            service0:{ head: "توريد المنتجات المتنوعة", paragraph: " توفير مستمر لمختلف المنتجات لتلبية احتياجات عملائنا المتغيرة." },
            service1:{ head: "ضمان الجودة", paragraph: "التزام بتقديم منتجات عالية الجودة تلبي المعايير الصارمة." },
            service2:{ head: "عقود التوريد الكبرى", paragraph: " إدارة ماهرة وتنفيذ عقود التوريد الكبيرة." },
            service3:{ head: "الجملة والتوزيع", paragraph: "شبكات توزيع قوية تضمن وصول المنتجات إلى السوق بفعالية." },
            service4:{ head: "التجارة العالمية", paragraph: "خبرة في عمليات الاستيراد والتصدير، مع التعامل مع اللوجستيات والجمارك والأنظمة بسهولة." }
        },
    },
    
    agenciesSector :{
        head: "قطاع الوكالات",
        paragraphs: {
            p0:"تفتخر مجموعة الجود بتكوين والحفاظ على شراكات استراتيجية تعزز التزامنا بالتميز. بينما نسعى جاهدين لنكون الأفضل في ما نقوم به، فإننا بكل فخر نعمل كممثلين رسميين في العراق لمجموعة مختارة من الشركات ذات الشهرة العالمية في مختلف القطاعات..",
            p1:"نحن ملتزمون بتوسيع شراكاتنا وتعزيز محفظتنا، مما يضمن استمرارنا في تقديم خدمات وفرص استثنائية لعملائنا وشركائنا على حد سواء. لمزيد من المعلومات حول فرص الشراكة، يرجى التواصل مع مجموعة الجود."
        },
        serviceDetails: {
            service0:{ head: "التمثيل الحصري", paragraph: "العمل كممثل رسمي للشركات العالمية المشهورة في العراق." },
            service1:{ head: "تغطية قطاعات متنوعة", paragraph: "الشراكة مع شركات متخصصة في قطاعات متعددة لتوفير حلول شاملة." },
            service2:{ head: "توسيع الأعمال", paragraph: "السعي المستمر لتوسيع شبكتنا من خلال إقامة علاقات وتعاون جديد." }
        },
    }
}
