import React, { useEffect } from 'react'
import Container from '../../Components/Container'
import { preset } from '../../TailwindClassPresets/ClassPresets'
import ProjectNode from '../../Components/Projects/ProjectNode'
import SloganNavy from "../../Images/SloganNavy.png"
import SloganWhite from "../../Images/SloganWhite.png"
import { editStyles, observeElements } from '../../script'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n/config'
export default function Projects() {
  useEffect(()=>{

    window.scrollTo(0, 0);
    observeElements();
    editStyles()
    
  },[])
  const {t} = useTranslation();
  return (
    <>
    <Container background='bg-white'>
        <div className='flex flex-col justify-between outOfView'>

            <div className='relative w-full flex justify-between items-start z-20 mb-14'>
                    <div>
                        <img className='size-20 md:hidden mb-4' src={SloganWhite} alt='Star'></img>
                        <h2 className={`${preset.h2} text-navy tracking-tight md:text-left md:text-6xl lg:text-8xl font-extrabold`}>{t("projects.title")}</h2>
                    </div>
                    <img className='hidden md:block size-32' src={SloganWhite} alt='Star'></img>

                    {/* <div className='hidden md:block size-32 mr-6 '></div> */}
                </div>
            <p className={`${preset.h4} bg-transparent z-20 text-navy md:w-[540px] font-extrabold mb-12`}>{t("projects.section0.title")}</p>

            
            {/* <div className="hidden md:block h-4 w-24 bg-navy absolute bottom-36 left-4"></div> */}

            <div className='flex justify-between gap-10 flex-wrap'>
                <ProjectNode text={{head:t("projects.section0.project0.head"), paragraph:t("projects.section0.project0.paragraph")}} sloganColor='white'/>
                <ProjectNode text={{head:t("projects.section0.project1.head"), paragraph:t("projects.section0.project1.paragraph")}} sloganColor='white'/>
                <ProjectNode text={{head:t("projects.section0.project2.head"), paragraph:t("projects.section0.project2.paragraph")}} sloganColor='white'/>
                <ProjectNode text={{head:t("projects.section0.project3.head"), paragraph:t("projects.section0.project3.paragraph")}} sloganColor='white'/>
            </div>
        
            <div className="block md:hidden h-2 w-12 bg-white mt-10"></div>

        </div>
    </Container>

    <Container background='bg-navy'>
        <div className='flex flex-col justify-between outOfView'>

            <p className={`${preset.h4} bg-transparent z-20 text-white md:w-[540px] font-extrabold mb-12`}>{t("projects.section1.title")}</p>

            
            {/* <div className="hidden md:block h-4 w-24 bg-navy absolute bottom-36 left-4"></div> */}

            <div className='flex justify-between gap-10 flex-wrap'>
                <ProjectNode text={{head:t("projects.section1.project0.head"), paragraph:t("projects.section1.project0.paragraph")}} sloganColor=''/>
                <ProjectNode text={{head:t("projects.section1.project1.head"), paragraph:t("projects.section1.project1.paragraph")}} sloganColor=''/>
                <ProjectNode text={{head:t("projects.section1.project2.head"), paragraph:t("projects.section1.project2.paragraph")}} sloganColor=''/>
                <ProjectNode text={{head:t("projects.section1.project3.head"), paragraph:t("projects.section1.project3.paragraph")}} sloganColor=''/>
            </div>
        
            <div className="block md:hidden h-2 w-12 bg-white mt-10"></div>

        </div>
    </Container>

    <Container background='bg-white'>
        <div className='flex flex-col justify-between outOfView'>

            <p className={`${preset.h4} bg-transparent z-20 text-navy md:w-[540px] font-extrabold mb-12`}>{t("projects.section2.title")}</p>

            
            {/* <div className="hidden md:block h-4 w-24 bg-navy absolute bottom-36 left-4"></div> */}

            <div className='flex justify-between gap-10 flex-wrap'>
                <ProjectNode text={{head:t("projects.section2.project0.head"), paragraph:t("projects.section2.project0.paragraph")}} sloganColor='white'/>
                <ProjectNode text={{head:t("projects.section2.project1.head"), paragraph:t("projects.section2.project1.paragraph")}} sloganColor='white'/>
                <ProjectNode text={{head:t("projects.section2.project2.head"), paragraph:t("projects.section2.project2.paragraph")}} sloganColor='white'/>
            </div>
        
            <div className="block md:hidden h-2 w-12 bg-white mt-10"></div>

        </div>
    </Container>
    
    <Container background='bg-navy'>
        <div className='flex flex-col justify-between outOfView'>

            <p className={`${preset.h4} bg-transparent z-20 text-white md:w-[540px] font-extrabold mb-12`}>{t("projects.section3.title")}</p>

            
            {/* <div className="hidden md:block h-4 w-24 bg-navy absolute bottom-36 left-4"></div> */}

            <div className='flex justify-between gap-10 flex-wrap'>
                <ProjectNode text={{head:t("projects.section3.project0.head"), paragraph:t("projects.section3.project0.paragraph")}} sloganColor=''/>
                <ProjectNode text={{head:t("projects.section3.project1.head"), paragraph:t("projects.section3.project1.paragraph")}} sloganColor=''/>
                <ProjectNode text={{head:t("projects.section3.project2.head"), paragraph:t("projects.section3.project2.paragraph")}} sloganColor=''/>
                <ProjectNode text={{head:t("projects.section3.project3.head"), paragraph:t("projects.section3.project3.paragraph")}} sloganColor=''/>
            </div>
        
            <div className="block md:hidden h-2 w-12 bg-white mt-10"></div>

        </div>
    </Container>

    <Container background='bg-white'>
        <div className='flex flex-col justify-between outOfView'>

            <p className={`${preset.h4} bg-transparent z-20 text-navy md:w-[540px] font-extrabold mb-12`}>{t("projects.section4.title")}</p>

            
            {/* <div className="hidden md:block h-4 w-24 bg-navy absolute bottom-36 left-4"></div> */}

            <div className='flex justify-between gap-10 flex-wrap'>
                <ProjectNode text={{head:t("projects.section4.project0.head"), paragraph:t("projects.section4.project0.paragraph")}} sloganColor='white'/>
                <ProjectNode text={{head:t("projects.section4.project1.head"), paragraph:t("projects.section4.project1.paragraph")}} sloganColor='white'/>
                <ProjectNode text={{head:t("projects.section4.project2.head"), paragraph:t("projects.section4.project2.paragraph")}} sloganColor='white'/>
                <ProjectNode text={{head:t("projects.section4.project3.head"), paragraph:t("projects.section4.project3.paragraph")}} sloganColor='white'/>
            </div>
        
            <div className="block md:hidden h-2 w-12 bg-white mt-10"></div>

        </div>
    </Container>
    </>

  )
}
