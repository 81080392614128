export const homepageArabic = {
    hero:{
        title: "الجود",
        slide0:{text:"التميز الهندسي", subText:"بناء البنية التحتية للغد اليوم بدقة وخبرة", tabText:"الهندسة"},
        slide1:{text:"خدمات حقول النفط", subText:"تمكين التقدم في صناعة النفط ، وضمان الجودة في كل خطوة على الطريق", tabText:"قطاع النفط"},
        slide2:{text:"حلول مستدامة لغد أكثر اخضرارا", subText:"الريادة في حماية البيئة والاستدامة من خلال الخدمات المبتكرة", tabText:"البيئة"},
        slide3:{text:"التميز الديناميكي في التصنيع", subText:"رفع معايير الصناعة بدقة وجودة", tabText:"الصناعة"},
        slide4:{text:"حلول التجارة العالمية", subText:"بناء الجسور وربط القارات وتحقيق التميز: شريكك الموثوق به في التجارة العالمية", tabText:"التجارة"},
        slide5:{text:"تحالفات استراتيجية، تضخيم النجاح", subText:"شراكات رائدة وتنمية الروابط وتمكين النمو من خلال التمثيل الديناميكي في العراق", tabText:"الوكالات"},

    },
    services:{
        ourServices:"خدماتنا",
        service0:{
            title:"خدمات القطاع الهندسي",
            paragraph:"تلتزم مجموعة الجود بالتميز في القطاع الهندسي، حيث تقدم مجموعة واسعة من الخدمات في التخصصات المدنية والميكانيكية والكهربائية والمعمارية. الالتزام بأعلى معايير السلامة والصحة والبيئة والجودة (SHEQ)."
        },
        service1:{
            title:"خدمات قطاع النفط",
            paragraph:"تقدم مجموعة الجود خدمات شاملة في قطاع النفط، مقسمة إلى فئتين فرعيتين رئيسيتين لتحقيق الوضوح الأمثل ومشاركة العملاء."
        },
        service2:{
            title:"القطاع البيئي",
            paragraph:"تلتزم مجموعة الجود بفخر بسياسة تتماشى بشكل صارم مع القوانين واللوائح والآليات المتعلقة بحماية البيئة واستدامتها."
        }
    },
    partners:{
        ourContractors:"مقاولونا",
        ourPartners:"شركاؤنا"
    },
    projects:{
        ourProjects:"مشاريعنا",
        project0:{
            title:"توريد والإشراف على نظام سكادا",
            paragraph:"تصميم وتوريد جميع معدات وبرمجيات نظام سكادا لمحطة الهندية لمعالجة المياه لصالح وزارة البلديات والأشغال العامة، بالتعاون مع شركة H&F Pro Aqua GmbH وشركة رؤية الرافدين المحدودة."
        },
        project1:{
            title:"أعمال المسح",
            paragraph:"لخط الجهد العالي 400 ك.ف، 132 ك.ف لصالح (المديرية العامة لمشاريع النقل الكهربائي ETP)."
        },
        project2:{
            title:"توريد معدات لشركة الجسر",
            paragraph:"توريد كافة المعدات الكهربائية لمحطات معالجة مياه الصرف الصحي في الجسر لصالح محافظة بغداد بالاشتراك مع شركة H&F Pro Aqua GmbH وشركة رؤية الرافدين المحدودة."
        }
    },
    global:"وصولنا العالمي",
    learnMore: "اكتشف المزيد"
} 