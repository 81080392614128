export const homepageEnglish = {
    hero:{
        title: "AL-JUUD",
        slide0:{text:"Engineering Excellence", subText:"Building Tomorrow's Infrastructure Today, with Precision and Expertise", tabText:"Engineering"},
        slide1:{text:"Oilfield Services", subText:"Empowering Progress in the Oil Industry, Ensuring Quality Every Step of the Way", tabText:"Oil & Gas"},
        slide2:{text:"Sustainable Solutions for a Greener Tomorrow", subText:"Leading the Way in Environmental Protection and Sustainability with Innovative Services", tabText:"Environment"},
        slide3:{text:"Dynamic Manufacturing Excellence", subText:"Elevating Industry Standards with Precision and Quality", tabText:"Manufacturing"},
        slide4:{text:"Global Trade Solutions", subText:"Building Bridges, Connecting Continents, and Delivering Excellence: Your Trusted Partner in Global Trade", tabText:"General Trade"},
        slide5:{text:"Strategic Alliances, Amplifying Success", subText:"Pioneering Partnerships, Cultivating Connections, and Empowering Growth Through Dynamic Representation in Iraq", tabText:"Agencies"},
    },
    services:{
        ourServices:"OUR SERVICES",
        service0:{
            title:"Engineering Sector Services",
            paragraph:"AL-Juud Group is committed to excellence in the Engineering sector, offering a broad spectrum of services across Civil, Mechanical, Electrical, and Architectural disciplines. Adhering to the highest standards of Safety, Health, Environment, and Quality (SHEQ)."
        },
        service1:{
            title:"Oil & Gas Sector Services",
            paragraph:"AL-Juud Group offers comprehensive services in the Oil & Gas Sector, categorized into two main subcategories for optimal clarity and client engagement."
        },
        service2:{
            title:"Environmental Commitment and Services",
            paragraph:"AL-Juud Group proudly upholds a policy rigorously aligned with laws, regulations, and mechanisms concerning environmental protection and sustainability."
        }
    },
    partners:{
        ourContractors:"OUR PARTNERS & CUSTOMERS",
        ourPartners:"OUR PARTNERS"
    },
    projects:{
        ourProjects:"OUR PROJECTS",
        project0:{
            title:"Supply and supervision of Scada System",
            paragraph:"Design and supply all Scada System equipment and software for Al-Hindia water treatment plant for the benefit of Ministry of Municipalities and Public Works, along with H&F Pro Aqua GmbH and Al-Rafidain Vision Ltd."
        },
        project1:{
            title:"Survey works",
            paragraph:"for high voltage line 400 K.V, 132 K.V, for the benefit of (Directorate General of Electrical Transmission Projects ETP)."
        },
        project2:{
            title:"Supply equipment for AL-Jisser",
            paragraph:"Supply all electrical equipment for Al-Jisser waste water treatment plants, for the benefit of Baghdad Governorate, along with H&F Pro Aqua GmbH and Al-Rafidain Vision Ltd."
        }
    },
    global:"OUR GLOBAL REACH",
    learnMore: "LEARN MORE"
} 