import React from 'react'
import { preset } from '../../TailwindClassPresets/ClassPresets';
import Star from '../../Icons/WhiteStarWithNoMarginSvg.svg';
import StarWithNoMargin from '../../Icons/StarWithNoMargin';
import SloganNavy from "../../Images/SloganNavy.png"

type ParagraphTypes = string | {title:string, description:string}[]

export default function ServiceDetail({text}:{text:{head: string; paragraph:ParagraphTypes}}) {
  return (
    <div className='flex flex-col max-w-[430px] text-white items-start mb-2 md:min-h-64 mx-auto  outOfView'>
        <img className='size-20 md:hidden mb-4 mt-7 ' src={SloganNavy} alt='Slogan'></img>
        <h3 className={`${preset.h5} md:h-20 `}>{text.head}</h3>
        <div className='flex gap-3'>
            <img className='hidden md:block h-28 w-40' src={SloganNavy} alt='Slogan'></img>
            {
              typeof text.paragraph === "string" ? (

                <p className='font-light text-sm md:text-base mt-3 w-full '>{text.paragraph}</p>
              ) : (
                <ul className='flex flex-col'>
                  {
                    text.paragraph.map((paragraph)=>(
                      <li className='font-light text-sm md:text-base mt-3 w-full '> <span className='font-medium text-xl'>{paragraph.title}</span> <br/> {paragraph.description}</li>
                    ))
                  }
                </ul>
              )
            }
        </div>
    </div>
  )
}
