export const NavigationRoutes = {
    Home: "/",
    Services:"/services",
    AboutUs: "/about-us",
    Projects: "/projects",
    Facebook: "https://www.facebook.com/aljuudgroup?mibextid=LQQJ4d",
    Linkedin: "https://www.linkedin.com/company/al-juud-group/",
    Youtube: "https://www.youtube.com/@aljuudgroup7515",
    Twitter: "#",
    Instagram: "#",
}