// const observer = new IntersectionObserver((entries)=>{
//     entries.forEach((entry)=>{
//         console.log(entry);
//         if (entry.isIntersecting){
//             entry.target.classList.add("show");
//         }
//     })
// })

import i18n from "./i18n/config";

export function observeElements() {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add("show");
            }
        });
    });

    const hiddenElements = document.querySelectorAll('.outOfView');
    hiddenElements.forEach((el) => observer.observe(el));
}

export function editStyles(){
    const app = document.getElementById("mainapp");
    if(app){
      app.style.direction = i18n.dir();
      app.style.fontFamily = i18n.resolvedLanguage === 'en' ? 'Jost, sans-serif' : 'Tajawal, sans-serif';
    } 


    const elementsWithClass = document.querySelectorAll('.fontSwitcher');
    if(elementsWithClass){
      elementsWithClass.forEach(element => {
          const el = element as HTMLElement;
          el.style.fontFamily = i18n.resolvedLanguage === 'en' ? 'Krona, sans-serif' : 'Tajawal, sans-serif';
          el.style.letterSpacing = '0em';
      });
    }

    const projectHeaders = document.querySelectorAll('.projectHeader');
    if(projectHeaders){
        projectHeaders.forEach(element => {
            const el = element as HTMLElement;
            el.style.fontFamily = i18n.resolvedLanguage === 'en' ? 'Krona, sans-serif' : 'Tajawal, sans-serif';
            el.style.letterSpacing = '0em';
        });
    }

    const heroImg = document.getElementById("hero");
    if(heroImg && i18n.resolvedLanguage === 'ar'){
        heroImg.classList.remove("md:right-0");
        heroImg.classList.add("md:left-0");
    }else{
        heroImg?.classList.remove("md:left-0");
        heroImg?.classList.add("md:right-0");
    }
    const ceoImg = document.getElementById("ceo");
    if(ceoImg && i18n.resolvedLanguage === 'ar'){
        ceoImg.classList.remove("md:right-0");
        ceoImg.classList.add("md:left-0");
    }else{
        ceoImg?.classList.remove("md:left-0");
        ceoImg?.classList.add("md:right-0");
    }
    const aboutimg = document.getElementById("aboutimg");
    if(aboutimg && i18n.resolvedLanguage === 'ar'){
        aboutimg.classList.remove("md:left-0");
        aboutimg.classList.add("md:right-0");
    }else{
        aboutimg?.classList.remove("md:right-0");
        aboutimg?.classList.add("md:left-0");
    }
    const heroGrad = document.getElementById("heroGrad");
    if(heroGrad && i18n.resolvedLanguage === 'ar'){
        heroGrad.classList.remove("blackToTransparent");
        heroGrad.classList.add("blackToTransparentReversed");
    }else{
        heroGrad?.classList.remove("blackToTransparentReversed");
        heroGrad?.classList.add("blackToTransparent");
    }


    const navbarlist = document.getElementById("navbarlist");
    if(navbarlist && i18n.resolvedLanguage === 'ar'){
        navbarlist.style.direction = "rtl"
    }else if(navbarlist && i18n.resolvedLanguage === 'en'){
        navbarlist.style.direction = "ltr"
    }

}