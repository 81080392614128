export const sharedArabicTranslations = {
    contact: {
    us: "تواصل معنا",
    paragraph: "إذا كنت مهتمًا بمعرفة المزيد عن الطريقة التي نعمل بها، أو لديك اقتراح عمل، أو مهتم بالعمل معنا، فنحن نحب أن نسمع منك.",
    name: "الاسم",
    lastName: "الاسم الاخير",
    email: "البريد الكتروني",
    message: "رسالة",
    submit: "قدم",
    placeholders: {
        name: "اسمك",
        lastName: "اسمك الاخير",
        email: "بريدك الالكتروني",
        message: "أدخل رسالتك",
    }
    },
    footer: {
        contacts: "جهات الاتصال",
        mob1: "هاتف 1",
        mob2: "هاتف 2",
        tel: "جوال",
        em: "بريد الكتروني",
        address: {
            title: "العنوان",
            line0: "فيلا 5، الحارثية، 213، 23",
            line1: "بغداد، العراق"
        }
    },
    navbar:{
        home: "الصفحة الرئيسية",
        about: "حولنا",
        services: "الخدمات",
        projects: "المشاريع"
    },
    profile:"ملف مجموعة الجود",
    download: "تحميل",
    name: "مجموعة الجود"
}