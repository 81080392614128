import i18n from "../i18n/config"
export const preset = {
    h1: `fontSwitcher ${i18n.language === 'en' ? "font-['Krona']" : "font-['Tajawal'] tracking-[0.01em]"} text-5xl md:text-9xl tracking-[0.01em]`,
    h2: `fontSwitcher ${i18n.language === 'en' ? "font-['Krona']" : "font-['Tajawal'] tracking-[0.01em]"} text-4xl md:text-8xl`,
    h3: `fontSwitcher ${i18n.language === 'en' ? "font-['Krona']" : "font-['Tajawal'] tracking-[0.01em]"} text-3xl md:text-5xl`,
    h4: `fontSwitcher ${i18n.language === 'en' ? "font-['Krona']" : "font-['Tajawal'] tracking-[0.01em]"} text-xl md:text-4xl`,
    h5: `fontSwitcher ${i18n.language === 'en' ? "font-['Krona']" : "font-['Tajawal'] tracking-[0.01em]"} text-lg md:text-2xl`,
    h6: `fontSwitcher ${i18n.language === 'en' ? "font-['Krona']" : "font-['Tajawal'] tracking-[0.01em]"} text-base`,
    special: `fontSwitcher ${i18n.language === 'en' ? "font-['Krona']" : "font-['Tajawal'] tracking-[0.01em]"} tracking-tight md:text-left text-3xl md:text-6xl lg:text-8xl`,

}
