import React, { useEffect } from 'react'
import Container from '../../Components/Container'
import { preset } from '../../TailwindClassPresets/ClassPresets'
import SloganNavy from "../../Images/SloganNavy.png"
import SloganWhite from "../../Images/SloganWhite.png"
import { editStyles, observeElements } from '../../script';
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n/config'
import aboutusimg from "../../Images/aboutus.jpg"
import ceo from "../../Images/ceo.jpg"
import alia from "../../Images/ourOrg/aila.png"
import aljuudgroup from "../../Images/logo.png"
import arv from "../../Images/ourOrg/ARV.png"
import diwan from "../../Images/ourOrg/diwan.png"
import minGross from "../../Images/ourOrg/minGross.png"
import ramz from "../../Images/ourOrg/ramz.png"
import trices from "../../Images/ourOrg/Trices.png"
import waha from "../../Images/ourOrg/waha.png"

export default function AboutUs() {
  useEffect(()=>{
    window.scrollTo(0, 0);
    observeElements();
    editStyles();
  },[])
  const {t} = useTranslation();
  return (
    <>
        {/* CODE BELOW IS VERY STUPID AND COULDA BEEN BETTER, I KNOW */}
        <Container background='bg-white'>
            <div className='size-full flex flex-col justify-between items-end relative gap-14 outOfView pb-0 md:pb-20 '>
                {/* <img className='absolute top-0 -left-12' alt='engineering' src="https://www.facturee.de/wp-content/uploads/shutterstock_1465371665-768x513.jpg"/> */}
                <div className='relative w-full flex justify-between items-center'>
                    {/* <span className='hidden md:block'><Star color='#171754'/></span> */}
                    <img className='hidden md:block size-32 mr-6' src={SloganWhite} alt='Star'></img>
                    <div>
                        <img className='size-20 md:hidden mb-7' src={SloganWhite} alt='Star'></img>
                        {/* <img className='block md:hidden size-10 mb-2 self-start' src={BlueStarWithNoMargin} alt='star'/> */}
                        <h2 className={`${preset.h2} text-navy tracking-tight text-right`}>{t("about.aboutUs.title")}</h2>
                    </div>
                </div>

                <div>
                    <p className='text-sm font-light md:max-w-[450px] lg:max-w-[550px] mt-3 first:mt-0 outleene'>{t("about.aboutUs.paragraph")}</p>
                </div>

                <img id='aboutimg'
                    className=' static md:absolute md:top-44 lg:top-36 md:left-0 -z-10 size-auto md:w-1/2 aspect-square object-cover max-h-[430px] outOfView'
                    alt="about us" loading='lazy' src={aboutusimg}/>

                <span className='h-2 w-12 md:h-4 md:w-24 self-end bg-navy'></span>
            </div>
        </Container>

        <Container background='bg-navy'>
            <div className='size-full flex flex-col justify-between items-start relative gap-14 outOfView'>
                {/* <img className='absolute top-0 -left-12' alt='engineering' src="https://www.facturee.de/wp-content/uploads/shutterstock_1465371665-768x513.jpg"/> */}
                <div className='relative w-full flex md:flex-row-reverse justify-between items-center'>
                    {/* <span className='hidden md:block'><Star color='#FFFFFF'/></span> */}
                    <img className='hidden md:block size-32 mr-6 self-start' src={SloganNavy} alt='Star'></img>
                    <div>
                        <img className='size-20 md:hidden mb-7' src={SloganNavy} alt='Star'></img>
                        <h2 className={`${preset.h2} text-white tracking-tight`}>{t("about.ceo.title")}</h2>
                    </div>
                </div>

                <div>
                    <p className='text-sm font-light max-w-[550px] mt-3 first:mt-0 text-white'>
                    {t("about.ceo.letter.first")} <br/>
                    {t("about.ceo.letter.second")}
                        <br/>{t("about.ceo.letter.third")} <br/> {t("about.ceo.letter.forth")}
                    </p>
                </div>

                <img id='ceo'
                    className=' static md:absolute md:top-44 lg:top-36 md:right-0 -z-10 size-auto md:w-1/4 aspect-square object-cover max-h-[430px] md:max-h-[430px] outOfView'
                    alt="about us" loading='lazy' src={ceo}/>

                <span className='h-2 w-12 md:h-4 md:w-24 self-start bg-white'></span>
            </div>
        </Container>


        <Container background='bg-white'>
            <div className='outOfView'>
                <div className='relative w-full flex justify-between items-center mb-12'>
                    {/* <span className='hidden md:block'><Star color='#171754'/></span> */}
                    <img className='hidden md:block size-32 mr-6' src={SloganWhite} alt='Star'></img>
                    <div>
                        <img className='size-20 md:hidden mb-7' src={SloganWhite} alt='Star'></img>
                        {/* <img className='block md:hidden size-10 mb-2 self-start' src={BlueStarWithNoMargin} alt='star'/> */}
                        <h2 className={`${preset.h2} text-navy tracking-tight text-right`}>{t("about.org")}</h2>
                    </div>
                </div>
                <div className="flex flex-col gap-10 items-center justify-center flex-wrap">
                    {/* This is stupid, i know, but it is easier, not in the mood to create objects so i can have different sizes c: */}
                    
                    <div className='flex flex-col items-center'>
                        <img className='h-64 object-cover' src={aljuudgroup} alt="logo" />
                        <div className={`${preset.h3} text-center text-[#999999] w-full mt-4 font-bold`}>{t("about.group")}</div>
                        <div className="w-full h-4 bg-navy mt-4"></div>
                    </div>
                    <div className='flex gap-10 items-start justify-center flex-wrap'>
                        <div className='w-32 flex flex-col gap-6'>
                            <div className='bg-navy size-32 rounded-3xl rotate-45 flex justify-center items-center'>
                                <img loading='lazy' className='h-28 object-cover -rotate-45' src={waha} alt="logo" />
                            </div>
                            <p className='text-center text-navy font-medium text-sm'>{t("about.wahat")}</p>
                        </div>

                        <div className='w-32 flex flex-col gap-6'>
                            <div className='bg-navy size-32 rounded-3xl rotate-45 flex justify-center items-center'>
                                <img loading='lazy' className='h-32 object-cover -rotate-45 ' src={ramz} alt="logo" />
                            </div>
                            <p className='text-center text-navy font-medium text-sm'>{t("about.ramz")}</p>
                        </div>

                        <div className='w-32 flex flex-col gap-6'>
                            <div className='bg-navy size-32 rounded-3xl rotate-45 flex justify-center items-center'>
                                <img loading='lazy' className='h-28 object-cover -rotate-45' src={diwan} alt="logo" />

                            </div>
                            <p className='text-center text-navy font-medium text-sm'>{t("about.diwan")}</p>
                        </div>

                        <div className='w-32 flex flex-col gap-6'>
                            <div className='bg-navy size-32 rounded-3xl rotate-45 flex justify-center items-center'>
                                <img loading='lazy' className='h-[75px] object-cover -rotate-45 ' src={alia} alt="logo" />

                            </div>
                            <p className='text-center text-navy font-medium text-sm'>{t("about.aila")}</p>
                        </div>
                        
                        <div className='w-32 flex flex-col gap-6'>
                            <div className='bg-navy size-32 rounded-3xl rotate-45 flex justify-center items-center'>
                                <p className='h-[75px] object-cover -rotate-45 text-white text-4xl translate-x-3 translate-y-3 font-["KRONA"]'>SAB</p>


                            </div>
                            <p className='text-center text-navy font-medium text-sm'>{t("about.hani")}</p>
                        </div>
                        {/* <img className='h-28 object-cover pl-4 md:p-0' src={minGross} alt="logo" /> */}
                        {/* <img className='h-32 object-cover' src={trices} alt="logo" /> */}
                        {/* <img className='h-24 object-cover' src={arv} alt="logo" /> */}
                        {/* {
                            contractors.map((logo, index)=>(
                                <img className='h-40 object-cover' key={index} src={logo} alt="logo" />
                            ))
                        } */}
                    </div>

                </div>
                <div className="flex w-full justify-end">
                    <div className="block md:h-4 md:w-24 h-2 w-12 bg-navy mt-10 "></div>
                </div>

            </div>
        </Container>


        <Container background='bg-navy'>
            <div className='size-full flex flex-col justify-between relative gap-20 outOfView pb-0'>
                <div className='relative w-full flex justify-between items-center mb-14'>
                    <img className='hidden md:block size-32 mr-6' src={SloganNavy} alt='Star'></img>
                    <div>
                        <img className='size-20 md:hidden mb-7' src={SloganNavy} alt='Star'></img>
                        <h2 className={`${preset.h2} text-white tracking-tight md:text-right`}>{t("about.mv.title")}</h2>
                    </div>
                </div>

                <div className='flex flex-col md:flex-row justify-around w-full'>
                    {/* <ProjectNode text={{head:t("about.mv.mission.title"), paragraph:t("about.mv.mission.paragraph")}} sloganColor='navy'/> */}
                    <div className='flex flex-col items-start max-w-[396px]'>
                            <img className='size-16  mb-7' src={SloganNavy} alt='Star'></img>


                        <div className={`${"text-white"} w-full md:max-w-[370px]`}>
                            <h6 className={`projectHeader font-['Krona'] text-xl font-semibold ${"text-white"}`}>{t("about.mv.mission.title")}</h6>
                            <p className='text-white font-thin leading-7'>{t("about.mv.mission.paragraph")}</p>
                        </div>
                    </div>
                    {/* <ProjectNode text={{head:t("about.mv.vision.title"), paragraph:t("about.mv.vision.paragraph")}} sloganColor='navy'/> */}
                    <div className='flex flex-col items-start max-w-[396px]'>
                            <img className='size-16  mb-7' src={SloganNavy} alt='Star'></img>


                        <div className={`${"text-white"} w-full md:max-w-[370px]`}>
                            <h6 className={`projectHeader font-['Krona'] text-xl font-semibold ${"text-white"}`}>{t("about.mv.vision.title")}</h6>
                            <p className='text-white font-thin leading-7'>{t("about.mv.vision.paragraph")}</p>
                        </div>
                    </div>
                </div>

                <span className='h-2 w-12 mt-20 md:h-4 md:w-24 self-end bg-navy'></span>
            </div>
        </Container>

    </>
  )
}
