import React from 'react'
import Container from '../../Components/Container'
import { preset } from '../../TailwindClassPresets/ClassPresets'
import ServicesNode from '../../Components/Homepage/ServicesNode'
import { Link } from 'react-router-dom'
import Button from '../../Components/Button'
import { NavigationRoutes } from '../../Components/Navbar/Routes'
import { useTranslation } from 'react-i18next'

export default function OurServices() {
  const {t} = useTranslation();
  return (
    <Container background='bg-navy'>
      <div className='flex flex-col md:flex-row justify-between outOfView'>

        <div className='bg-transparent z-20 font-["Krona"] text-white w-[340px] flex flex-col justify-between h-full md:min-h-[848px]'>
          <p className={`${preset.h3} font-extrabold mb-12`}>{t("homepage.services.ourServices")}</p>
          <div className="hidden md:block h-4 w-24 bg-white "></div>
        </div>

        <div className='flex flex-col justify-between gap-10'>
          <ServicesNode text={{head:t("homepage.services.service0.title"), paragraph:t("homepage.services.service0.paragraph")}}/>
          <ServicesNode text={{head:t("homepage.services.service1.title"), paragraph:t("homepage.services.service1.paragraph")}}/>
          <ServicesNode text={{head:t("homepage.services.service2.title"), paragraph:t("homepage.services.service2.paragraph")}}/>
          <Link to={NavigationRoutes.Services} className='md:self-center group md:mt-10'>
            <Button title={t("homepage.learnMore")} specialClasses='bg-white text-navy group-hover:bg-darkGray group-hover:text-white'/>
          </Link>
        </div>
        <div className="block md:hidden h-2 w-12 bg-white mt-10"></div>

      </div>
    </Container>
  )
}
