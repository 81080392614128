import i18next, { changeLanguage } from 'i18next';
import React, { useState } from 'react'
import i18n from '../i18n/config';
import language from "../Icons/language.svg"

export default function LanguageSwitcher() {
    const [oppositeLanguage, setOppositeLanguage] = useState<Language>(usedLanguages.arabic);

    async function switchLanguage(language: string) {
    //   changeLanguage(language);
      i18n.changeLanguage(language)
      let lang = i18n.language === "ar" ? usedLanguages.english : usedLanguages.arabic;
      setOppositeLanguage(lang);


      const app = document.getElementById("mainapp");
      if(app){
        app.style.direction = i18n.dir();
        app.style.fontFamily = i18n.resolvedLanguage === 'en' ? 'Jost, sans-serif' : 'Tajawal, sans-serif';
      } 


      const elementsWithClass = document.querySelectorAll('.fontSwitcher');
      if(elementsWithClass){
        elementsWithClass.forEach(element => {
            const el = element as HTMLElement;
            el.style.fontFamily = i18n.resolvedLanguage === 'en' ? 'Krona, sans-serif' : 'Tajawal, sans-serif';
            el.style.letterSpacing = '0em';
        });
      }
      console.log(i18n.resolvedLanguage)
    }
    
  return (
    <div className='flex gap-1 cursor-pointer'
      onClick={() => switchLanguage(oppositeLanguage.code)}
    >
        <img alt='globe' src={language} className="size-6" color="#102067" />

      <ul className="font-medium text-base xl:text-base select-none text-right">
        <li className="block">
          {oppositeLanguage.fullName}
        </li>
        {/* <li className="xl:hidden" onClick={() => console.log(i18next.language)}>
          {oppositeLanguage.abbreviation}
        </li> */}
      </ul>



    
    </div>
  )
}


export type Language = {
    abbreviation: string;
    fullName: string;
    code: string;
};

type Languages = {
    [key: string]: Language;
};

export const usedLanguages: Languages = {
    arabic:{
        abbreviation: "ع",
        fullName: "العربية",
        code:"ar"
    },
    english:{
        abbreviation: "En",
        fullName: "English",
        code:"en"
    }
}