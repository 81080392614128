import isNil from './isNil';
import isNull from './isNull';
import isUndefined from './isUndefined';
import isPureObject from './isPureObject';
import setValueIfNotNil from './setValueIfNotNil';

export {
  isNil,
  isNull,
  isUndefined,
  isPureObject,
  setValueIfNotNil,
};
