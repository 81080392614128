import React from 'react'
import SloganNavy from "../../Images/SloganNavy.png"
import SloganWhite from "../../Images/SloganWhite.png"

export default function ProjectNode({text, sloganColor}:{text:{head: string; paragraph:string;}; sloganColor: string}) {
  return (
    <div className='flex flex-col items-start max-w-[296px]'>
      {
          sloganColor === "white" ? (<img className='size-16  mb-7' src={SloganWhite} alt='Star'></img>)
          : 
          (<img className='size-16  mb-7' src={SloganNavy} alt='Star'></img>)
      }

      <div className={`${sloganColor === 'white' ? "text-navy" : "text-white"} w-full md:max-w-[370px]`}>
          <h6 className={`projectHeader font-['Krona'] text-lg font-semibold ${sloganColor === 'white' ? "text-navy" : "text-white"}`}>{text.head}</h6>
        <p className='font-thin leading-7'>{text.paragraph}</p>
      </div>
    </div>
  )
}