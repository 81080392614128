import React, { useEffect } from 'react'
import logo from "../../Images/logo.png"
import { Link } from 'react-router-dom'
import { NavigationRoutes } from '../Navbar/Routes'
import { preset } from '../../TailwindClassPresets/ClassPresets'
import youtube from "../../Icons/youtube.png"
import linkedin from "../../Icons/linkedin.png"
import twitter from "../../Icons/twitter.png"
import facebook from "../../Icons/facebook.png"
import { useTranslation } from 'react-i18next'
import { editStyles } from '../../script'

export default function Footer() {
    useEffect(()=>{
        editStyles();
    })
    const {t} = useTranslation();
  return (
    <footer className='w-screen flex flex-col bg-white z-40 justify-center mx-auto md:h-60 py-10'>
        <div className='flex flex-col gap-10 md:flex-row justify-around items-start w-full md:max-w-[1232px] px-2 mx-auto '>
            <div className='flex md:flex-col gap-6 justify-between w-full md:w-fit h-full'>
                <Link to={NavigationRoutes.Home}><img alt='Logo' src={logo} className='w-12 md:w-16 h-14 md:h-20'/></Link>
                <ul className='grid grid-cols-2 place-items-center gap-0.5 mr-4 md:mr-0 rotate-45'>
                    <li className='bg-navy p-2 rounded-xl hover:opacity-80'><Link target='_blank' to={NavigationRoutes.Linkedin}><img className='-rotate-45  size-5' alt='linkedin' src={linkedin}/></Link></li>
                    <li className='bg-navy p-2 rounded-xl hover:opacity-80'><Link target='_blank' to={NavigationRoutes.Facebook}><img className='-rotate-45  size-5' alt='facebook' src={facebook}/></Link></li>
                    <li className='bg-navy p-2 rounded-xl hover:opacity-80'><Link target='_blank' to={NavigationRoutes.Youtube}><img className='-rotate-45  size-5' alt='youtube' src={youtube}/></Link></li>
                    <li className='bg-navy p-2 rounded-xl hover:opacity-80'><Link target='_blank' to={NavigationRoutes.Twitter}><img className='-rotate-45  size-5' alt='twitter' src={twitter}/></Link></li>
                </ul>
            </div>
            <div className='w-full md:w-fit px-2 md:px-0'>
                <h5 className={`${preset.h6} text-darkGray mb-6 font-semibold`}>{t("shared.footer.contacts")}</h5>
                <div className='flex flex-col gap-2 text-sm leading-[1.2rem] ' >
                    <div className=' inline tracking-wider' >{t("shared.footer.mob1")}: <div className='inline' style={{direction:'ltr'}}>+(964)7901886966</div></div>
                    <div className=' inline tracking-wider' >{t("shared.footer.mob2")}: <div className='inline' style={{direction:'ltr'}}>+(964)7906886966</div></div>
                    <div className=' inline tracking-wider' >{t("shared.footer.tel")}: <div className='inline' style={{direction:'ltr'}}>+(964)7831448080</div></div>
                    <p className=''>{t("shared.footer.em")}: info@aljuudgroup.com</p>
                </div>
            </div>
            <div className='px-2 md:px-0'>
                <h5 className={`${preset.h6} text-darkGray mb-6 mx-auto font-semibold`}>{t("shared.footer.address.title")}</h5>
                <div className='flex flex-col gap-2 text-sm leading-[1.2rem]'>
                    <p>{t("shared.footer.address.line0")}</p>
                    <p>{t("shared.footer.address.line1")}</p>
                </div>
            </div>
        </div>
        {/* <ul className='hidden md:flex items-center justify-center gap-10 mt-6'>
			<li><Link to={NavigationRoutes.Twitter}><img className='size-5' alt='twitter' src={twitter}/></Link></li>
			<li><Link to={NavigationRoutes.Facebook}><img className='size-6' alt='facebook' src={facebook}/></Link></li>
			<li><Link to={NavigationRoutes.Instagram}><img className='size-5' alt='instagram' src={instagram}/></Link></li>
		</ul> */}
    </footer>
  )
}
