import React, { ReactNode } from 'react'

export default function Container({children, background}: {children: ReactNode; background: string}) {
  return (
    <div className={`${background} w-screen flex justify-center mx-auto`}>
        <div className='px-4 py-20 md:py-36 lg:mx-0 max-w-[328px] md:max-w-[1224px] md:min-h-[727px] w-full relative'>
            {children}
        </div>
    </div>
  )
}
