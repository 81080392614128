export const aboutUsEnglish = {
    aboutUs: {
        title: "ABOUT US",
        paragraph: "Al-Juud Group of Companies was founded in early 1992 by establishing Al-Aeela complex of factories and over the years and based on our commitment to meet our customers expectations, the group has grown steadily by establishing new companies and divisions to cover our customers demand, and from these beginnings we have expand widely by providing high quality of service and since that time our development is apparent over the years. Al-Juud group of companies is multi-faceted organization with operations in widely different fields. Al-Juud Group of Companies was founded in early 1992 by establishing Al-Aeela complex of factories and over the years and based on our commitment to meet our customers expectations, the group has grown steadily by establishing new companies and divisions to cover our customers demand, and from these beginnings we have expand widely by providing high quality of service and since that time our development is apparent over the years. Al-Juud group of companies is multi-faceted organization with operations in widely different fields. Our Activities include large-scale in Engineering sector which covers (Civil, Mechanical, Electrical and Architectural), Oil & Gas sector, Environmental sector, Manufacturing sector, General Trade sector and Agencies sector. Our group has set principles in our trading business,, the contracts do not end at the delivery time ,but, ensure that our customers are satisfied with the products they got by providing after sale services, and this sets the group apart from its competitors and provide the opportunity to create long term relationship with our customers."
    },
    ceo:{
        title: "CEO LETTER",
        letter: {
            first: "Dear client and partners,",
            second:"On behalf of Al-Juud Group of companies, I would like to thank you for choosing our Group to be your destination to get the solution to your projects and getting the proper products. I can tell you that we are motivated and inspired by the opportunities that you give us to be your partners in executing your projects. Our main focus is to know our customers demand and treat them as partners and friend,. Over the years we have remained dedicated to developing our people strengthening our capabilities and building trusting relationships with our clients and partners, and this is our best investment we make each day. We hope you will find one stop station of what you are looking of solutions which fall with the sphere competence of our companies. The principles and standards we follow made our group one of the most reputed group locally and globally . We are excited for the future and looking forward for the best.",
            third: "Sincerely,",
            forth: "Saif Al-Hani"
        }
    },
    mv:{
        title: "OUR MISSION & VISION",
        mission:{
            title:"OUR MISSION",
            paragraph:"At AL-Juud Group of Companies, our mission is to relentlessly pursue excellence in every aspect of our operations, guided by a steadfast commitment to our customers, partners, and communities. We aspire to be more than just a provider of solutions; we aim to be trusted advisors and dependable allies in our clients' journey towards success."
        },
        vision:{
            title: "OUR VISION",
            paragraph: "We believe that success comes from cooperation and we put our group resources at the service of our customers and partners, in order to create long term value for our stakeholders, we are proud of our history and looking forward for a bright future to be a recognized leader in our field in terms of quality and performance."
        }
    },
    org: "OUR GROUP",
    group: "AL-JUUD GROUP",
    wahat: "WAHAT AL-JUUD COMPANY FOR GENERAL CONTRACTING AND TRADING .LTD.",
    diwan: "DEWAN AL JUUD COMPANY FOR GENERAL TRADING CONTRACTING AND COMMERCIAL AGENCIES LTD.",
    ramz: "RAMZ AL JUUD COMPANY FOR GENERAL TRADING . LTD.",
    aila:"AL-AEELA COMPLEX FOR DETERGENTS & SULPHONATION",
    hani: "S. AL-HANI BUREAU IMPORTING & EXPORTING"
}