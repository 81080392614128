export const sharedEnglishTranslations = {
    contact: {
        us: "CONTACT US",
        paragraph: "If you're interested in hearing more about the way we work, have a business proposal, or are interested in working with us, we'd love to hear from you.",
        name: "Name",
        lastName: "Last name",
        email: "Your email",
        message: "Message",
        submit: "SUBMIT",
        placeholders: {
            name: "Your name",
            lastName: "Your last name",
            email: "Your email address",
            message: "Enter your message",
        }
    },
    footer: {
        contacts: "CONTACTS",
        mob1: "Tel1",
        mob2: "Tel2",
        tel: "Mob",
        em: "E-mail",
        address: {
            title: "ADDRESS",
            line0: "VILLA 5 ,HARTHIA, 213, 23",
            line1: "Baghdad, Iraq"
        }
    },
    navbar:{
        home: "Home",
        about: "About us",
        services: "Services",
        projects: "Projects"
    },
    profile:"AL-JUUD GROUP PROFILE",
    download: "DOWNLOAD",
    name: "AL-JUUD GROUP"
}