export const projectsEnglish = {
    title: "OUR PROJECTS",
    section0: {
        title: "DIRECT EXECUTION PROJECTS",
        project0:{head:"Compact STP Tikrit hospital", paragraph:""},
        project1:{head:"Handicap rehabilitation center", paragraph:"4 centers – Baghdad."},
        project2:{head:"Al-Shoumukh residential complex", paragraph:"Baghdad."},
        project3:{head:"Residential complex for the benefit of the ministry of industry", paragraph:"AL-Nibaee – Iraq."},
    },
    section1:{
        title: "AL-HANI BUREAU",
        project0:{head:"STP hospital complex", paragraph:"Mousil – Ninawa governorate"},
        project1:{head:"Filters for Zanquraa", paragraph:"Al-Anbar governorate"},
        project2:{head:"Rehabilitation of Kirkuk unified WTP ", paragraph:""},
        project3:{head:"Rehabilitation of rain water pump station", paragraph:"Al-Diwanea governorate"},
    },
    section2:{
        title: "WTP PROJECTS",
        project0:{head:"AL-Kadumia WTP", paragraph:"25 million gallon per day – Subcontract – turnkey job – Baghdad."},
        project1:{head:"AL-Baladiat WTP", paragraph:" 2×25 million gallon per day – Managing and supervision – Baghdad."},
        project2:{head:"AL-Qadisia WTP", paragraph:"20 million gallon per day – Managing – Baghdad."},
    },
    section3:{
        title: "SUPPLYING PROJECTS",
        project0:{head:"Electrical equipment", paragraph:"Supply all electrical equipment for Al-Jisser waste water treatment plants ,for the benefit of Baghdad Governorate ,along with H&F Pro Aqua GmbH and Al-Rafidain Vision Ltd."},
        project1:{head:"Lighting poles", paragraph:"Ministry of Industry and minerals."},
        project2:{head:"Shrink machines", paragraph:"Ministry of Industry and minerals."},
        project3:{head:"Scada Systems", paragraph:"Design and supply all Scada System equipment and software for Al-Hindia water treatment plant for the benefit of Ministry of Municipalities and Public Works , along with H&F Pro Aqua GmbH and Al-Rafidain Vision Ltd."},
    },
    section4:{
        title: "OTHER PROJECTS",
        project0:{head:"Survey works", paragraph:"For high voltage line 400 K.V – 132 K.V , for the benefit of (Directorate General of Electrical Transmission Projects ETP)."},
        project1:{head:"Al-Dairee company", paragraph:"sulphonation plant – Syria."},
        project2:{head:"Al-Aeela powder detergent factory", paragraph:"Iraq."},
        project3:{head:"Al-Aeela factory for sulphonation plant", paragraph:"Iraq."},
    }
}