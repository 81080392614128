/*external modules*/
/*types*/
/*utils*/
/*lib*/
/*other*/

function isNull(value: unknown): value is null {
  return value === null;
}

export default isNull;
