import React from 'react'
import Star from '../../Icons/Star'
// import WhiteStarSvg from "../../Icons/WhiteStarSvg.svg"
import SloganNavy from "../../Images/SloganNavy.png"
import { preset } from '../../TailwindClassPresets/ClassPresets';

export default function ServicesNode({text}:{text:{head: string; paragraph:string;}}) {
  return (
    <div className='flex flex-col md:flex-row items-start'>
        <span className='hidden md:block'>
          {/* <Star color='white'/> */}
          <img className='size-32 mx-6' src={SloganNavy} alt='Star'></img>

        </span>
        <img className='size-20 md:hidden mb-7' src={SloganNavy} alt='Star'></img>

        <div className='text-white w-full md:max-w-[370px]'>
          <h6 className={`${preset.h5} mb-4 md:mb-7`}>{text.head}</h6>
          <p className='font-thin leading-7'>{text.paragraph}</p>
        </div>
        
    </div>
  )
}
