import React from 'react'
import examplePDF from './Al-Juud-Group-Profile.pdf'
import { preset } from '../../../TailwindClassPresets/ClassPresets';
import { useTranslation } from 'react-i18next';
export default function PDFDownload() {    
    const {t} = useTranslation();
    return (
        <div className={`bg-white w-screen flex justify-center mx-auto`}>
            <div className='flex flex-col gap-6 justify-center items-center h-64 w-full  max-w-[328px] md:max-w-[1224px]'>
                <h4 className={`${preset.h4} text-navy font-semibold`}>{t("shared.profile")}</h4>
                <a href={examplePDF} download="Al-Juud-Group-Profile.pdf">
                    <button className="button1 bg-navy text-white hover:opacity-80 flex justify-center items-center">
                        <p className="text1 text-center absolute">
                            {t("shared.download")} 
                        </p>
                        <div className="svg1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="white" className="bi bi-download" viewBox="0 0 16 16"> <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path> <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path> </svg>
                        </div>
                    </button>
                </a>
            </div>
        </div>
    );
}
