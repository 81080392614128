import * as dotenv from 'dotenv';
import Mailjet from 'node-mailjet';
import { FormValuesType } from '../Components/ContactUs/ContactUs';

// dotenv.config();
// export default function SendEmail(name:string, lastName: string, email: string, message: string){
export default function SendEmail(props: FormValuesType){
    const publicKey = "6849a86f1c3748068330b0e0d62f5499";
    const privateKey = "61a1f7c306325553b12e1376f1171a37"

    const mailjet = Mailjet.apiConnect(
        // process.env.MJ_APIKEY_PUBLIC as string,
        // process.env.MJ_APIKEY_PRIVATE as string,
        publicKey, privateKey
    );

    const request = mailjet
        .post('send', { version: 'v3.1' })
        .request({
            Messages: [
                {
                    From: {
                        Email: props.email,
                        Name: `${props.name} ${props.lastName}`
                    },
                    To: [
                        {
                            Email: "eminjay.mj9@yahoo.com",
                            Name: "passenger 1"
                        }
                    ],
                    Subject: "A message from a costumer",
                    TextPart: props.message,
                    // HTMLPart: "<h3>Dear passenger 1, welcome to <a href='https://www.mailjet.com/'>Mailjet</a>!</h3><br />May the delivery force be with you!"
                    HTMLPart: `<h3>A customer send a message:<br />${props.message}</h3>`
                }
            ]
        });

    request.then((result: any) => {
        console.log(result.body);
    }).catch((err: any) => {
        console.log(err.statusCode);
    });

}